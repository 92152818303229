import { useState, useContext } from "react";
import { MenuItem, InputLabel, FormControl } from "@mui/material";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import P2PBackendService from "../../services/p2papiservice";
import { User } from "../../context/context";
import web3service from "../../services/web3service";
import { LoadingButton } from "@mui/lab";
import { emptyAddress } from "../../components/AddressTemplate";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import { states } from "../../components/selectOptions";
import { getCoordinates } from "../../components/getCoordinates";

const emptyRateObject = {
  fromTime: "",
  toTime: "",
  price: "",
};

const textFieldStyles = {
  "&.MuiTextField-root": { marginTop: "28px" },
  width: { xs: "100%", md: "45%" },
};

function AddProducingStation({
  open,
  handleClose,
  mode = "add",
  setToast,
  setAddedNewStation,
}) {
  const publicAddress = localStorage.getItem("publicAddress");

  const { user } = useContext(User);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [capacity, setCapacity] = useState("");
  const [type, setType] = useState("");
  const [prices, setPrices] = useState({ 0: emptyRateObject });
  const [address, setAddress] = useState(emptyAddress);

  //call sc and be methods to add a new consuming station for the current user
  const addProducingStation = async () => {
    setLoading(true);
    let pricesPayload = Object.keys(prices)
      .filter((i) => prices[i])
      .map((i) => prices[i]);

    // Get coordinates
    let addressWithCoordinates = await getCoordinates(address);

    let payload = {
      name: name,
      UserId: user.id,
      type: type,
      Address: addressWithCoordinates,
      capacity: capacity,
      prices: pricesPayload,
    };

    const registerStatus = await web3service.addProducingStation(
      publicAddress,
      capacity
    );
    if (registerStatus === undefined) {
      // Show an error here
      setLoading(false);
      setToast({
        message:
          "There was an error adding the producing station, please try again later.",
        severity: "error",
      });
    } else {
      payload.onChainIndex =
        registerStatus.events?.EntityEvent?.returnValues?.entityId;
      // Make API call to the backend
      payload = JSON.stringify(payload);
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          publicAddress: publicAddress,
        },
        keepalive: true,
        body: payload,
        redirect: "follow",
      };
      const res = await P2PBackendService.addProducingStation(config);
      if (!res?.ok) {
        // Show error toast
        setToast({
          message:
            "There was an error adding the producing station, please try again later.",
          severity: "error",
        });
      } else {
        setToast({
          message: "Successfully added producing station!",
          severity: "success",
        });
        // Clear input fields
        setAddress(emptyAddress);
        setName("");
        setCapacity(0);
      }
    }
    setLoading(false);
  };

  // Calls addProducingStation() to save the station, and closes the modal
  const saveStation = async (e) => {
    e.preventDefault(); // prevent page refresh
    await addProducingStation();
    // To refetch the list of producing stations
    setAddedNewStation((p) => p + 1);
    // Close the dialog
    handleClose();
  };

  //   Modify the prices object when a price is changed.
  const handlePriceChange = (e, i, attr) => {
    setPrices((p) => ({ ...p, [i]: { ...p[i], [attr]: e.target.value } }));
  };

  return (
    <Dialog
      open={open}
      onClose={(e, r) => {
        r !== "backdropClick" && handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="md"
    >
      <Box padding={2}>
        <Typography variant="h5" fontWeight={600} p={"20px 24px"}>
          {mode === "add" ? "Add Producing Station" : "Edit Consuming Station"}
        </Typography>
        <form onSubmit={(e) => saveStation(e)}>
          <DialogContent sx={{ paddingTop: 0 }}>
            <Box display="flex" flexWrap="wrap" justifyContent="space-between">
              <TextField
                size="small"
                id="cs-name-field"
                label="Station name"
                variant="outlined"
                sx={{
                  ...textFieldStyles,
                  width: { xs: "100%", md: "45%" },
                }}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              />
              <TextField
                size="small"
                id="cs-meter-field"
                label="Capacity (kWh)"
                variant="outlined"
                type="number"
                sx={{
                  ...textFieldStyles,
                  width: { xs: "100%", md: "45%" },
                }}
                value={capacity}
                onChange={(e) => {
                  setCapacity(e.target.value);
                }}
                required
              />
              <TextField
                size="small"
                id="cs-address1-field"
                label="Street address"
                variant="outlined"
                sx={{
                  ...textFieldStyles,
                  width: { xs: "100%", md: "45%" },
                }}
                value={address.line1}
                onChange={(e) => {
                  setAddress((p) => ({ ...p, line1: e.target.value }));
                }}
                required
              />
              <TextField
                size="small"
                id="cs-address2-field"
                label="Street address 2"
                variant="outlined"
                sx={{
                  ...textFieldStyles,
                  width: { xs: "100%", md: "45%" },
                }}
                value={address.line2}
                onChange={(e) => {
                  setAddress((p) => ({ ...p, line2: e.target.value }));
                }}
              />
              <TextField
                size="small"
                id="cs-city-field"
                label="City"
                variant="outlined"
                sx={{
                  ...textFieldStyles,
                  width: { xs: "100%", md: "30%" },
                }}
                value={address.city}
                onChange={(e) => {
                  setAddress((p) => ({ ...p, city: e.target.value }));
                }}
                required
              />
              <FormControl
                size="small"
                sx={{
                  "&.MuiFormControl-root": { marginTop: "28px" },

                  width: { xs: "100%", md: "30%" },
                }}
              >
                <InputLabel> State</InputLabel>
                <Select
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  value={address.state.abbreviation}
                  label="State"
                  onChange={(e) => {
                    setAddress((p) => ({ ...p, state: e.target.value }));
                  }}
                >
                  {states.map((state) => (
                    <MenuItem
                      key={state.abbreviation}
                      value={state.abbreviation}
                    >
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                type="number"
                size="small"
                id="cs-zip-field"
                label="ZIP Code"
                variant="outlined"
                sx={{
                  ...textFieldStyles,
                  width: { xs: "100%", md: "30%" },
                }}
                value={address.zip}
                onChange={(e) => {
                  setAddress((p) => ({ ...p, zip: e.target.value }));
                }}
                required
              />
              <FormControl
                size="small"
                sx={{
                  width: { xs: "100%", md: "45%" },
                  marginTop: "28px",
                }}
              >
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  value={type}
                  label="Type"
                  required
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value="Individual">Individual</MenuItem>
                  <MenuItem value="Grid">Grid</MenuItem>
                  <MenuItem value="Enterprise">Enterprise</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Typography fontWeight={600} variant="body1" marginTop={4}>
              Rates
            </Typography>
            {Object.keys(prices)
              .filter((i) => prices[i])
              .map((i) => (
                <Stack
                  direction={{ sm: "column", md: "row" }}
                  spacing={3}
                  alignItems="flex-end"
                  marginBottom={3}
                  key={i}
                >
                  <TextField
                    size="small"
                    id="cs-mno-field"
                    label="From"
                    variant="outlined"
                    sx={{
                      ...textFieldStyles,
                      width: { xs: "100%", md: "25%" },
                    }}
                    value={prices[i].fromTime}
                    onChange={(e) => {
                      handlePriceChange(e, i, "fromTime");
                    }}
                    required
                    type="time"
                    focused
                  />
                  <TextField
                    size="small"
                    label="To"
                    variant="outlined"
                    sx={{
                      ...textFieldStyles,
                      width: { xs: "100%", md: "25%" },
                    }}
                    value={prices[i].toTime}
                    onChange={(e) => {
                      handlePriceChange(e, i, "toTime");
                    }}
                    required
                    type="time"
                    focused
                  />
                  <TextField
                    size="small"
                    label="Rate (USD)"
                    variant="outlined"
                    sx={{
                      ...textFieldStyles,
                      width: { xs: "100%", md: "20%" },
                    }}
                    type="number"
                    value={prices[i].price}
                    onChange={(e) => {
                      handlePriceChange(e, i, "price");
                    }}
                    required
                  />
                  {i > 0 && (
                    <Button
                      sx={{ height: "100%" }}
                      color="error"
                      onClick={() => {
                        setPrices((p) => ({ ...p, [i]: null }));
                      }}
                      disabled={loading}
                    >
                      Remove
                    </Button>
                  )}
                </Stack>
              ))}
            <Button
              variant="contained"
              disabled={loading}
              onClick={() =>
                setPrices((p) => ({
                  ...p,
                  [Object.keys(prices).length]: emptyRateObject,
                }))
              }
            >
              Add rate
            </Button>
          </DialogContent>
          <DialogActions sx={{ marginTop: "30px" }}>
            <Button
              onClick={handleClose}
              sx={{ color: "#718096" }}
              disabled={loading}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              type="submit"
              autoFocus
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
}

export default AddProducingStation;
