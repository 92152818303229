import { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Rating,
  List,
  ListItem,
  Typography,
  Divider,
} from "@mui/material";

import PageTitle from "../../components/PageTitle";
import P2PBackendService from "../../services/p2papiservice";

function Reviews({ open, handleClose, producingStation }) {
  const publicAddress = localStorage.getItem("publicAddress");
  const [reviews, setReviews] = useState(null);

  useEffect(() => {
    const handleViewReviews = async (producingStation) => {
      // API call to fetch producingStation's reviews
      let res = await P2PBackendService.getProducingStationReviews(
        producingStation.id,
        publicAddress
      );
      // TODO_BE: Make API errors uniform
      if (!res.message) {
        res = await res.json();
        setReviews(res?.reviews?.rows);
      } else {
        // Show toast saying error fetching reviews
      }
    };

    producingStation && handleViewReviews(producingStation);
  }, [publicAddress, producingStation]);
  //   When user clicks view reviews against a particular table row

  return (
    <Dialog
      open={open}
      onClose={() => {
        setReviews(null);
        handleClose();
      }}
      maxWidth="md"
    >
      <Box minWidth={{ xs: "100%", md: "400px" }} padding={2}>
        <PageTitle
          title={`User reviews for ${producingStation?.name}`}
          subtext=""
          type="sub"
        />
        <DialogContent sx={{ p: 0 }}>
          {reviews && reviews.length > 0 ? (
            reviews.map((r) => (
              <List key={r.id}>
                <ListItem sx={{ display: "block", padding: 2 }}>
                  <Rating name="read-only" value={r.rating || 0} readOnly />
                  <Typography gutterBottom>{r.comment}</Typography>
                  <Typography variant="subtitle2">
                    Posted on:{" "}
                    {new Date(r.createdAt).toLocaleDateString("us-EN")}
                  </Typography>
                </ListItem>
                <Divider light />
              </List>
            ))
          ) : (
            <Typography p={2}>No user reviews at this time.</Typography>
          )}
        </DialogContent>
        <DialogActions sx={{ marginTop: "30px" }}>
          <Button onClick={handleClose} sx={{ color: "#718096" }}>
            Close
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default Reviews;
