import { useState, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Box from "@mui/material/Box";

import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";

import Sidebar from "./components/sidebar/sidebar";
import { User } from "./context/context";
import AppNavbar from "./components/navbar/navbar";

import { privateRoutes, publicRoutes } from "./routes";
import Topbar from "./components/Topbar/Topbar";

const Layout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { user, isLogged } = useContext(User);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box>
      <CssBaseline />
      {isLogged ? (
        <Box sx={{ display: "flex" }}>
          <Topbar user={user} handleDrawerToggle={handleDrawerToggle} />
          <Sidebar
            handleDrawerToggle={handleDrawerToggle}
            mobileOpen={mobileOpen}
          />
          <Box
            component="main"
            flexGrow={1}
            // p={6}
            p={{ xs: 6, md: 8 }}
            sx={{ background: "#F9FAFB" }}
            minHeight="calc(100vh - 10px)"
            height={"100%"}
          >
            <Toolbar />
            <Routes>
              {privateRoutes.map((route) => (
                <Route
                  path={route.path}
                  element={<route.element />}
                  key={route.path}
                />
              ))}
              <Route path="*" element={<Navigate to="/consumingstations" />} />
            </Routes>
          </Box>
        </Box>
      ) : (
        <Box>
          <AppNavbar />

          <Routes>
            {publicRoutes.map((route) => (
              <Route
                path={route.path}
                element={<route.element key={route.path} />}
              />
            ))}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Box>
      )}
    </Box>
  );
};

export default Layout;
