import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupText,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import web3service from "../../services/web3service";
import P2PBackendService from "../../services/p2papiservice";
import { toast } from "react-toastify";
import { FetchEthVal } from "../helpers/schemavalidation";
import Toast from "../../components/Toast";
// import { clearTimeout } from "timers";

//register layout component
const Register = () => {
  const styles = {
    header: {
      textAlign: "center",
      alignSelf: "center",
      color: "grey",
    },
    name: {
      display: "flex",
      width: "100%",
    },
    input: {
      width: "200%",
    },
    lname: {
      paddingLeft: "18rem",
    },

    form: {
      position: "relative",
      top: "3rem",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      maxWidth: "500px",
      margin: "auto",
      justifyContent: "space-between",
    },
    button: {
      margin: "1.8rem",
      padding: "8px 28px",
    },
  };

  const emptyuser = {
    firstname: "",
    lastname: "",
    publicAddress: "",
    email: "",
    deposit: 0.0,
  };
  let navigate = useNavigate();
  const [user, setuser] = useState(emptyuser);
  const [walletAddress, setWalletAddress] = useState("");
  const [emailState, setEmailState] = useState("");
  const [validDeposit, setValidDeposit] = useState("");
  const [deposit, setDeposit] = useState("");
  const [toast, setToast] = useState({ message: "", severity: "success" });

  useEffect(() => { }, [walletAddress]);

  //to track the deposit amount of the user
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "deposit") {
      setDeposit(value);
    } else {
      setuser({ ...user, [name]: value });
    }
  };

  //validate if the email id is of correct format
  const validateEmail = (e) => {
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailRex.test(e.target.value)) {
      setEmailState("has-success");
    } else {
      setEmailState("has-danger");
    }
  };
  let timer;
  //validate if the deposit is apropriate
  const isValidDeposit = async (e) => {
    let value = e.target.value;
    let curVal = e.target.value;
    clearTimeout(timer);
    timer = setTimeout(async () => {
      value = await FetchEthVal(value);
      const deposit = process.env.REACT_APP_DEPOSIT;
      if (curVal >= parseInt(deposit)) {
        setValidDeposit("has-success");
        setuser({ ...user, deposit: value });
      } else {
        setValidDeposit("has-danger");
        setuser({ ...user, deposit: value });
      }
    }, 500);
    setDeposit(curVal);
  };

  //call to be and  sc to add a new user
  const handleAddUser = async (event) => {
    event.preventDefault();
    if (parseFloat(event.target.deposit.value) < parseFloat(process.env.REACT_APP_DEPOSIT)) {
      setToast({
        message: "Minimum deposit amount should be " + process.env.REACT_APP_DEPOSIT,
        severity: "warning",
      });
      return;
    }
    var myHeaders = new Headers();

    if (window.ethereum) {
    } else {
    }

    if (validDeposit === "has-success") {
      const account = await web3service.getAccount();

      setWalletAddress(account);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("publicAddress", account);

      var data = JSON.stringify({
        firstname: user.firstname,
        lastname: user.lastname,
        publicAddress: account,
        email: user.email,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        keepalive: true,
        body: data,
        keepalive: true,
        redirect: "follow",
      };
      console.log(data, "data")

      // console.log("account", await web3service.getAccount())
      // await web3service.unregisterUser();
      setToast({
        message: "Your request is being processed",
        severity: "info",
      });
      let registerStatus;
      try {
        registerStatus = await web3service.register(account, user.deposit);
        await P2PBackendService.addUser(requestOptions);
        setToast({
          message: "Your registration has been completed successfully. You can now proceed to sign in",
          severity: "success",
        });
        navigate("/", { replace: true });
      } catch (err) {
        console.log('err', err)
        setToast({
          message: err.message,
          severity: "error",
        });
      }

      // toast.promise(registerStatus, {
      //   pending: "Processing",
      //   success: "Registered Successfully",
      //   error: "Registration Failed",
      // });
      console.log(registerStatus, "registerStatus")

      if (registerStatus === 'pending') {
        setToast({
          message: "Your request is being processed",
          severity: "info",
        });
      } else if (registerStatus === 'success') {
        setToast({
          message: "Your registration has been completed successfully. You can now proceed to sign in",
          severity: "success",
        });
      } else if (registerStatus === 'error') {
        setToast({
          message: "Your registration was unsuccessful. Please try again or contact support for further assistance",
          severity: "error",
        });
      }

      // navigate("/", { replace: true });
    } else {
      setToast({
        message: "Your deposit is insufficient. Please try with a higher amount",
        severity: "error",
      });
      navigate("/", { replace: true });

    }
  };

  return (
    <div>
      <Container className="bg-light-border" fluid="md">
        <h4 style={styles.header}>Let's Take This Further...</h4>
        <Form style={styles.form} onSubmit={handleAddUser}>
          <Toast
            message={toast.message}
            severity={toast.severity}
            open={Boolean(toast.message)}
            handleClose={() =>
              setToast((p) => ({ message: "", severity: p.severity }))
            }
          />
          <Row>
            <Col className="w-50">
              <FormGroup inline>
                <Label for="title">First Name</Label>
                <Input
                  type="text"
                  name="firstname"
                  id="firstname"
                  value={user.firstname}
                  onChange={(e) => handleChange(e)}
                  autoComplete="title"
                  placeholder="John"
                />
              </FormGroup>
            </Col>
            <Col className="w-50">
              <FormGroup inline>
                <Label for="title">Last Name</Label>
                <Input
                  type="text"
                  name="lastname"
                  id="lastname"
                  value={user.lastname}
                  onChange={(event) => handleChange(event)}
                  autoComplete="title"
                  placeholder="Doe"
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              valid={emailState === "has-success"}
              invalid={emailState === "has-danger"}
              value={user.email}
              onChange={(e) => {
                handleChange(e);
                validateEmail(e);
              }}
              autoComplete="price"
              placeholder="username@example.com"
            />
            <FormFeedback>
              Uh oh! Looks like there is an issue with your email. Please input
              a correct email.
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="deposit">deposit</Label>
            <InputGroup className="w-50">
              <Input
                type="number"
                name="deposit"
                id="deposit"
                valid={validDeposit === "has-success"}
                invalid={validDeposit === "has-danger"}
                value={deposit}
                onChange={(e) => {
                  isValidDeposit(e);
                }}
                autoComplete="deposit"
                placeholder="0.00"
              />
              <InputGroupText>USD</InputGroupText>
              <FormFeedback>Must be greater than {process.env.REACT_APP_DEPOSIT} USD</FormFeedback>
            </InputGroup>
          </FormGroup>

          <FormGroup>
            <Button color="success" type="submit" style={styles.button}>
              Save
            </Button>
            <Button color="secondary" tag={Link} to="/" style={styles.button}>
              Cancel
            </Button>
          </FormGroup>

          <p className="text-center mt-5">
            Already have an account?{" "}
            <Link className="text-secondary" to="/login">
              Sign In
            </Link>
          </p>
        </Form>
      </Container>
    </div>
  );
};

export default Register;
