import { Typography, Box, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const CardTextGrp = ({ name, value, info }) => {
  return (
    <Box minWidth="150px" mb={1}>
      <Box display="flex">
        <Typography variant="subtitle1" marginRight={1}>
          {name}
        </Typography>
        {info && (
          <Tooltip title={info} placement="top-start">
            <InfoIcon />
          </Tooltip>
        )}
      </Box>
      <Typography variant="h6" fontWeight={600}>
        {value}
      </Typography>
    </Box>
  );
};

export default CardTextGrp;
