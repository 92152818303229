import { useState, useContext } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import PageTitle from "../../components/PageTitle";
import TextField from "@mui/material/TextField/TextField";
import web3service from "../../services/web3service";
import { User } from "../../context/context";

function WithdrawFunds({ open, handleClose, setToast }) {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const { setBalance, toeth } = useContext(User);
  const account = localStorage.getItem("publicAddress");

  //get the account balance of the current user
  const getAccountBalance = async () => {
    try {
      // Get user's account balance from SC
      const b = await web3service.getBalance(account);
      // Set user's account balance in the global state
      setBalance(Number(b));
    } catch (error) {
      console.log("Error", error);
    }
  };

  //add funds to user's P2P balance
  //Adding funds to the user account
  const withdrawFunds = async (event) => {
    event.preventDefault();
    setLoading(true);
    let ethbal = Number(amount * toeth);
    try {
      const withdrawalStatus = await web3service.withdrawFunds(account, ethbal);
      console.log("rs", withdrawalStatus);
      // Refetch user's account balance since we added more funds
      getAccountBalance();
      setLoading(false);
      setToast();
      setToast({
        message: `Successfully withdrew ${amount} USD from user's balance!`,
        severity: "success",
      });
      handleClose();
    } catch (e) {
      console.log("Error", e);
      setLoading(false);
      setToast({
        message: `There was an error withdraw funds. Please try again later.`,
        severity: "error",
      });
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(e, r) => {
        r !== "backdropClick" && handleClose();
      }}
      maxWidth="md"
    >
      <Box padding={2}>
        <Box p="20px 24px">
          <PageTitle
            title="Please enter the amount"
            subtext="Please enter the amount(in USD) that you want to withdraw from your Power2Peer funds"
            type="sub"
          />
        </Box>
        <form onSubmit={withdrawFunds}>
          <DialogContent>
            <TextField
              label="Amount (USD)"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              type="number"
              size="small"
              required
            />
          </DialogContent>
          <DialogActions sx={{ marginTop: "30px" }}>
            <Button
              onClick={handleClose}
              sx={{ color: "#718096" }}
              disabled={loading}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              type="submit"
              autoFocus
            >
              Withdraw
            </LoadingButton>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
}
export default WithdrawFunds;
