import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { privateRoutes } from "../../routes";
import { useLocation } from "react-router-dom";

const drawerWidth = 250;

function Sidebar({ handleDrawerToggle, mobileOpen }) {
  const location = useLocation();
  console.log(location?.pathname);
  const navigate = useNavigate();

  const onSidebarClick = (route) => {
    navigate(route.path);
    handleDrawerToggle();
  };

  const drawer = (
    <List>
      {privateRoutes
        .filter((route) => route.sideBar)
        .map((route, index) => (
          <ListItem key={route.name} disablePadding>
            <ListItemButton
              onClick={() => onSidebarClick(route)}
              disableRipple={true}
            >
              {route.logo && (
                <Box sx={{ mr: "8px" }}>
                  <route.logo />
                </Box>
              )}
              <ListItemText
                primary={route.name}
                primaryTypographyProps={
                  location?.pathname === route.path
                    ? { sx: { fontWeight: 600 } }
                    : {}
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
    </List>
  );

  return (
    <>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>{drawer}</Box>
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        open
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>{drawer}</Box>
      </Drawer>
    </>
  );
}
export default Sidebar;
