import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../../context/context";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as P2PLogo } from "../../assets/logo.svg";

import LogoutIcon from "@mui/icons-material/Logout";
import web3service from "../../services/web3service";
import { FetchEthtoUsd } from "../helpers/schemavalidation";

function Topbar({ handleDrawerToggle }) {
  const {
    user,
    setUser,
    tousd,
    setTousd,
    balance,
    setBalance,
    setToeth,
    setIsLogged,
  } = useContext(User);

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    //get the account balance of the current user
    const getAccountBalance = async () => {
      const account = localStorage.getItem("publicAddress");

      try {
        const b = await web3service.getBalance(account);
        let { toEth, toUsd } = await FetchEthtoUsd();

        setTousd(toUsd);
        setToeth(toEth);

        setBalance(Number(b));
      } catch (error) {
        console.log("Error", error);
      }
    };

    getAccountBalance();
  }, [setToeth, setTousd, setBalance]);

  //TODO: This needs to be changed
  const logout = () => {
    localStorage.clear();
    navigate("/", { replace: true });
    setUser(null);
    setIsLogged(false);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: "linear-gradient(90deg, #2B6CB0 0%, #C63F7B 100%);",
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { md: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }}>
          <P2PLogo />
        </Box>
        <div>
          <Button
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Typography>{user?.firstname}</Typography>
          </Button>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            sx={{ borderRadius: "6px" }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Box sx={{ width: "250px" }} p={1}>
              <Stack spacing={2}>
                <Box sx={{ padding: "0 16px" }}>
                  <Typography color="#718096">{`${user?.firstname} ${
                    user?.lastName ? user.lastName : ""
                  } `}</Typography>
                  <Typography color="#718096">{user?.email}</Typography>
                </Box>
                <Box sx={{ padding: "0 16px" }}>
                  <Typography variant="subtitle2">Account Balance:</Typography>
                  <Typography fontWeight={600}>
                    {(tousd * balance).toFixed(2)} <b>USD</b> (
                    {balance.toFixed(4)} ETH)
                  </Typography>
                </Box>
                <Divider light />
                <MenuItem onClick={() => logout()}>
                  <LogoutIcon sx={{ marginRight: 1 }} />
                  Logout
                </MenuItem>
              </Stack>
            </Box>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Topbar;
