import { useState, useEffect } from "react";
import { Button, Box, Stack, Divider } from "@mui/material";
import PageTitle from "../../components/PageTitle";

import StationCard from "../../components/StationCard";
import CardTextGrp from "../../components/CardTextGrp";
import AddProducingStation from "./AddProducingStation";
import ConfirmationModal from "../ConsumingStations/ConfirmationModal";
import Toast from "../../components/Toast";
import P2PBackendService from "../../services/p2papiservice";
import web3service from "../../services/web3service";

const ProducingStations = () => {
  // We'll use this for all API calls
  const publicAddress = localStorage.getItem("publicAddress");

  // Feed this to the PageTitle component
  const title = "My Producing Stations";
  const subtext =
    "Add new producing stations, or view the ones previously added.";
  const [addStationModalOpen, setAddStationModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [toast, setToast] = useState({ message: "", severity: "success" });
  const [addedNewStation, setAddedNewStation] = useState(0); // Temporary state variable to refetch producingstations. TODO: smart update list of consumers once new API is ready
  const [loading, setLoading] = useState(false);
  const [producingStations, setProducingStations] = useState(null);

  const [deleteActionDetails, setDeleteActionDetails] = useState({
    title: "Confirm delete?",
    text: "",
    station: null,
  });

  // Fetch user's producing stations
  useEffect(() => {
    async function getUserDetails() {
      let options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          publicAddress: publicAddress,
        },
        keepalive: true,
        redirect: "follow",
      };
      let res = await P2PBackendService.getProducingStations(options);
      res = await res.json();
      setProducingStations(res.data);
    }
    getUserDetails();
  }, [addedNewStation, publicAddress]);

  const confirmDelete = (station) => {
    setConfirmationModalOpen(true);
    setDeleteActionDetails((p) => ({
      ...p,
      text: `Are you sure you want to delete the producing station "${station.name}"`,
      station: station,
    }));
  };

  // Delete a producing station
  const removeProducingStation = async () => {
    setLoading(true);
    // Need a try catch here. Transaction failure is not handled gracefully
    try {
      // Call blockchain method
      const requestStatus = await web3service.removeProducingStation(
        deleteActionDetails.station?.onChainIndex,
        publicAddress
      );

      // If blockchain method succeeded
      if (requestStatus !== undefined) {
        var payload = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            publicAddress: publicAddress,
          },
        };

        let res = await P2PBackendService.removeProducingStation(
          payload,
          deleteActionDetails.station?.id
        );
        if (!res.errors) {
          // res = await res.json();
          setAddedNewStation((p) => p + 1);
          // Show success
          setToast({
            message: "Successfully deleted the producing station!",
            severity: "success",
          });
        } else {
          // Show error
          setToast({
            message:
              "There was an error deleting the producing station, please try again later.",
            severity: "error",
          });
        }
      }
    } catch (e) {
      // Show error
      setToast({
        message:
          "There was an error deleting the producing station, please try again later.",
        severity: "error",
      });
    }
    setLoading(false);
    setConfirmationModalOpen(false);
  };

  return (
    <>
      <Stack width={{ xs: "100%", md: "90%" }} maxWidth="700px" spacing={4}>
        {/* Header */}
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <PageTitle title={title} subtext={subtext} />
          <Button
            variant="contained"
            sx={{ minWidth: "80px" }}
            onClick={() => setAddStationModalOpen(true)}
          >
            Add +
          </Button>
        </Box>

        {/* Body */}
        {producingStations && (
          <Stack spacing={2}>
            {producingStations
              .filter((producingStation) => producingStation.active)
              .map((producingStation) => (
                <StationCard key={producingStation.id}>
                  <Stack spacing={2}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <CardTextGrp name="Name" value={producingStation.name} />
                      <CardTextGrp
                        name="Type"
                        value={producingStation.type || "-"}
                        // info="This is the number on your meter"
                      />
                      <CardTextGrp
                        name="Capacity"
                        value={producingStation.capacity + " kWh"}
                      />

                      <CardTextGrp
                        name="RECs"
                        value={Math.floor(
                          producingStation.energyProduced / 1000000
                        )}
                        info={
                          "To obtain the next Renewable Energy Certificate (REC), generate and sell " +
                          (1000000 -
                            (producingStation.energyProduced % 1000000)) +
                          " kWhs"
                        }
                      />
                    </Box>
                    <Divider />
                    <Box alignSelf="flex-end">
                      <Button
                        color="error"
                        onClick={() => confirmDelete(producingStation)}
                      >
                        Delete
                      </Button>
                      {/* <Button>Edit</Button> */}
                    </Box>
                  </Stack>
                </StationCard>
              ))}
          </Stack>
        )}
      </Stack>

      {/* Modal */}
      <AddProducingStation
        open={addStationModalOpen}
        handleClose={() => setAddStationModalOpen(false)}
        setToast={setToast}
        setAddedNewStation={setAddedNewStation}
        setLoading={setLoading}
      />
      {/* Toast message */}
      <Toast
        message={toast.message}
        severity={toast.severity}
        open={Boolean(toast.message)}
        handleClose={() => setToast({ message: "", severity: "success" })}
      />
      {/* Confirmation modal */}
      <ConfirmationModal
        open={confirmationModalOpen}
        details={deleteActionDetails}
        confirmAction={() => removeProducingStation()}
        handleClose={() => setConfirmationModalOpen(false)}
        loading={loading}
      />
    </>
  );
};

export default ProducingStations;
