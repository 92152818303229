import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useContext } from "react";
import Jumbotron from "./jumbotron";
import P2pconnect from "./p2pconnect";
import "../../css/video.css";
import Ourtechnology from "./ourtechnology";
import Aboutus from "./aboutus";
import Footer from "../footer/footer";
import p2pconnect from "./p2pconnect";
import { User } from "../../context/context";
import Popup from "../navbar/modal";
const Home = () => {
  const { modalShow, setModalShow } = useContext(User);

  return (
    <div className="home">
      <Jumbotron ref={p2pconnect} />
      <P2pconnect />
      <Popup show={modalShow} onHide={() => setModalShow(false)} />
      <Ourtechnology />
      <Aboutus />
      <Footer />
    </div>
  );
};

export default Home;
