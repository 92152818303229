import React from "react";
import "../../css/video.css";
//video component on the landing page
const Video = ({ embedId }) => (
  <div className="vid video-responsive">
    <iframe
      width="480"
      height="300"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

export default Video;
