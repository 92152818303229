export const emptyAddress = {
  line1: "",
  line2: "",
  city: "",
  state: "",
  zip: "",
  location: {
    type: "Point",
    coordinates: [43.01, -78.99],
  },
};
