import { useEffect, useState, useContext } from "react";

import { Box, Stack, Chip, Button } from "@mui/material";
import Typography from "@mui/material/Typography";

import { User } from "../../context/context";
import P2PBackendService from "../../services/p2papiservice";
import PageTitle from "../../components/PageTitle";
import { boxStyles } from "../../components/commonStyles";
import Toast from "../../components/Toast";
import RecommendedProducers from "./RecommendedProducers";
import AddFunds from "./AddFunds";
import WithdrawFunds from "./WithdrawFunds";

function Marketplace() {
  // Feed this to the PageTitle component
  const title = "Marketplace";
  const subtext =
    "Explore different energy producers to meet your consumption needs.";

  // We'll use this for all API calls
  const publicAddress = localStorage.getItem("publicAddress");

  const { balance, tousd } = useContext(User);
  const [toast, setToast] = useState({ message: "", severity: "success" });

  const [consumingStations, setConsumingStations] = useState(null);
  const [selectedCS, setSelectedCS] = useState(null);
  const [fundsModalOpen, setFundsModalOpen] = useState(false);
  const [withdrawFundsModalOpen, setWithdrawFundsModalOpen] = useState(false);

  const confirmAddFunds = () => {
    setFundsModalOpen(true);
  };

  const confirmWithdrawFunds = () => {
    setWithdrawFundsModalOpen(true);
  };

  // Get list of user's consuming stations
  useEffect(() => {
    async function getUserDetails() {
      let options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          publicAddress: publicAddress,
        },
        keepalive: true,
        redirect: "follow",
      };
      let res = await P2PBackendService.signIn(options);
      res = await res.json();
      setConsumingStations(res.data?.ConsumingStations);
    }

    getUserDetails();
  }, [publicAddress]);

  return (
    <>
      <PageTitle title={title} subtext={subtext} />
      <Stack spacing={3} marginTop={2}>
        <Stack direction={{ xs: "column", md: "row" }}>
          <Box
            width="260px"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            sx={{ ...boxStyles, backgroundColor: "#FFFFFF" }}
          >
            <Typography variant="subtitle1">Account Balance</Typography>
            <Typography
              variant="h6"
              fontWeight={600}
              color="primary"
              gutterBottom
            >
              {(balance * tousd).toFixed(2)} USD <br />({balance.toFixed(4)}{" "}
              ETH)
            </Typography>
            <Box display="flex" flexDirection="row" gap={2}>
              <Button
                variant="contained"
                size="small"
                color="success"
                onClick={() => confirmAddFunds()}
              >
                Add funds
              </Button>
              <Button
                variant="contained"
                size="small"
                color="success"
                onClick={() => confirmWithdrawFunds()}
              >
                Withdraw Funds
              </Button>
            </Box>
          </Box>
        </Stack>
        <Box sx={{ ...boxStyles }}>
          <PageTitle
            title={"Pick a consuming station"}
            subtext={"Choose the consuming station you want to buy power for"}
            type="sub"
          />

          <Stack direction="row" flexWrap="wrap" spacing={2} marginTop={2}>
            {consumingStations &&
              consumingStations
                .filter((cs) => cs.active)
                .map((cs) => (
                  <Chip
                    sx={{ marginBottom: "8px!important" }}
                    key={cs.id}
                    label={cs.name}
                    color="primary"
                    variant={selectedCS?.id === cs.id ? "filled" : "outlined"}
                    onClick={() => setSelectedCS(cs)}
                  />
                ))}
          </Stack>
        </Box>
        <RecommendedProducers setToast={setToast} selectedCS={selectedCS} />
      </Stack>

      {/* Toast message */}
      <Toast
        message={toast.message}
        severity={toast.severity}
        open={Boolean(toast.message)}
        handleClose={() =>
          setToast((p) => ({ message: "", severity: p.severity }))
        }
      />
      {/* Add funds modal */}
      <AddFunds
        open={fundsModalOpen}
        handleClose={() => setFundsModalOpen(false)}
        setToast={setToast}
      />
      {/* Withdraw funds modal */}
      <WithdrawFunds
        open={withdrawFundsModalOpen}
        handleClose={() => setWithdrawFundsModalOpen(false)}
        setToast={setToast}
      />
    </>
  );
}

export default Marketplace;
