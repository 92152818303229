import React from "react";
import { Navbar, NavLink, NavItem } from "reactstrap";
import "../../css/navbar.css";
//footer layout component
const Footer = () => {
  const styles = {
    navItem: {
      listStyleType: "none",
      color: "white",
    },
    navLink: {
      listStyleType: "none",
      color: "white",
      gap: "1rem",
    },
    main: {
      display: "flex",
      justifyContent: "space-around",
      marginTop: "50px",
    },
  };
  const gotop2p = () => {
    window.scrollTo({
      top: 800,
      behavior: "smooth",
    });
  };

  const ourtech = () => {
    window.scrollTo({
      top: 2100,
      behavior: "smooth",
    });
  };

  const aboutus = () => {
    window.scrollTo({
      top: 4320,
      behavior: "smooth",
    });
  };

  return (
    <Navbar
      className="appnavbar d-flex space-around"
      style={styles.main}
      bg="dark"
      variant="dark"
    >
      <div className="container-fluid d-flex justify-content-around">
        <NavItem style={styles.navItem}>
          <NavLink style={styles.navLink} href="#p2pconnect" onClick={gotop2p}>
            About
          </NavLink>
        </NavItem>
        <NavItem style={styles.navItem}>
          <NavLink style={styles.navLink} href="#ourtech" onClick={ourtech}>
            Support
          </NavLink>
        </NavItem>
        <NavItem style={styles.navItem}>
          <NavLink style={styles.navLink} href="#about us" onClick={aboutus}>
            Legal
          </NavLink>
        </NavItem>
      </div>
    </Navbar>
  );
};

export default Footer;
