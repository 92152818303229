import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";

const StationCard = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(2),
  //   paddingBottom: 0,
  color: theme.palette.text.secondary,
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
  borderRadius: "6px",
}));

export default StationCard;
