import React from "react";
//about us layout compoennt
const Aboutus = () => {
  const styles = {
    header: {
      backgroundImage: `url('/images/shutterstock_536590567w.jpg')`,
      width: "100%",
      height: "500px",
      margin: "0",
      position: "absolute",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      opacity: "0.1",
      textAlign: "left",
      zIndex: "1",
    },
    header2: {
      backgroundImage: `url('/images/shutterstock_1297209118w.jpg')`,
      width: "100%",
      height: "2203px",
      margin: "0",
      position: "absolute",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      opacity: "0.1",
      textAlign: "left",
      zIndex: "1",
    },
    header3: {
      backgroundImage: `url('/images/shutterstock_1315034816w.jpg')`,
      width: "100%",
      height: "500px",
      margin: "0",
      position: "absolute",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      opacity: "0.1",
      textAlign: "left",
      zIndex: "1",
    },
    title: {
      textAlign: "center",
      opacity: "1 !important",
      top: "3rem",
    },
    component: {
      textAlign: "center",
      opacity: "1 !important",
      top: "3rem",
    },
    image: {
      verticalAlign: "middle",
      height: "212.96px",
      width: "320.67px",
      justifyContent: "center",
      marginTop: "2rem",
      marginLeft: "35%",
      opacity: "1 !important",
    },

    content: {
      height: "100%",
      width: "100%",
      paddingBottom: "6rem",
    },

    text: {
      margin: "10px",
      textAlign: "left",
      padding: "1rem 8rem",
      color: "#666666",
    },
    sectionContent: {
      position: "relative",
      zIndex: "20",
      top: "2 rem",
    },

    hr: {
      backgroundImage: `url('/images/hr1.png')`,
      position: "relative",
      left: "45%",
      height: "7px",
      width: "164px",
    },
    main: {
      marginTop: "4.4rem",
    },
    section2: {
      position: "relative",
      clear: "both",
    },
    video: {
      paddingBottom: "3rem",
      align: "center",
    },
    heading: {
      paddingLeft: "8rem",
    },
  };

  return (
    <div style={styles.main}>
      <div style={styles.header3}> </div>
      <div className="section-content" style={styles.sectionContent}>
        <h2 className="sec-title ex" style={styles.title}>
          ABOUT US:
        </h2>
        <div className="hr" style={styles.hr}></div>
        <p className="subheader" style={styles.title}>
          Power2Peer Connect App{" "}
        </p>
        <div className="content">
          <p style={styles.text}>
            Updating the grid to meet the challenges of the 21st century means
            going peer-to-peer. It’s high time consumers had a say in how their
            electricity is produced. And for producers, peer-to-peer power
            trading means enhanced returns on both residential- and
            commercial-scale renewable energy projects.
          </p>
          <p style={styles.text}>
            By decentralizing the flow of power, P2PConnect combines the potency
            of open markets with the mutual benefits of collective action.
            That's because at Power2Peer, we understand that making renewable
            energy more financially viable is the best chance we have at
            reducing carbon emissions and conserving the health of our planet.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
