const baseUrl = process.env.REACT_APP_BACKEND_API_URL;

//BE API call to Add a new user
const addUser = async (config) => {
  const relativeUrl = `api/v1/users/`;

  const url = `${baseUrl}${relativeUrl}`;

  return fetch(url, config)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res);
      }
      return handleResponse(res);
    })
    .catch((e) => console.log("Error", e));
};

//BE API call to Add a new producer to the current user
const addProducingStation = async (config) => {
  const relativeUrl = `api/v1/producingStations/`;

  const url = `${baseUrl}${relativeUrl}`;

  return fetch(url, config)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res);
      }
      return res;
    })
    .catch((e) => handleErrors(e));
};

//BE API call to Add a new consumer to the current user
const addConsumingStation = async (config) => {
  const relativeUrl = `api/v1/consumingstations/`;

  const url = `${baseUrl}${relativeUrl}`;

  return fetch(url, config)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res);
      }
      return res;
    })
    .catch((e) => handleErrors(e));
};

//BE Api call to create a new service between the selected producing station and consuming station
const addService = async (config) => {
  const relativeUrl = `api/v1/services/`;

  const url = `${baseUrl}${relativeUrl}`;

  return fetch(url, config)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res);
      }
      return res;
    })
    .catch((e) => {
      handleErrors(e);
      return {
        message:
          "There was an issue adding this service. Please try again later.",
        ok: false,
      };
    });
};

//BE Api call to sign in the registered user
const signIn = async (config) => {
  const relativeUrl = `api/v1/users/`;

  const url = `${baseUrl}${relativeUrl}`;

  return fetch(url, config)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res);
      }
      return res;
    })
    .catch((e) => {
      return "error";
    });
};
//BE Api call to fetch the user producing services
const userProducingService = async (id, config) => {
  const relativeUrl = `api/v1/services/producer/${id}`;

  const url = `${baseUrl}${relativeUrl}`;
  return fetch(url, config).then((res) => {
    return res;
  });
};

//BE API call to fetch the user consuming services
const userConsumingService = async (id, config) => {
  const relativeUrl = `api/v1/services/consumer/${id}`;

  const url = `${baseUrl}${relativeUrl}`;
  return fetch(url, config).then((res) => {
    return res;
  });
};

//BE API call to fetch the user services
const userServices = async (config) => {
  const relativeUrl = `api/v1/services/`;

  const url = `${baseUrl}${relativeUrl}`;
  return fetch(url, config)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res);
      }
      return res;
    })
    .then((res) => res.json())
    .catch((e) => handleErrors(e));
};

//BE API call to get the recommended list of producers for the current user
const getRecommended = async (config, params = "") => {
  const relativeUrl = `api/v1/producingStations/recommended/${params}`;
  const url = `${baseUrl}${relativeUrl}`;

  const myHeaders = {
    "Content-Type": "application/json",
    publicAddress: config.publicAddress,
  };

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    let res = await fetch(url, requestOptions);
    return res;
  } catch (e) {
    console.error(e);
  }

  // return fetch(url, requestOptions)
  //   .then((res) => {
  //     return res;
  //   })
  //   .catch((e) => handleErrors(e));
};

//BE API call for performing energy transer by transacting the amount
const recordEnergyTransfer = async (config) => {
  const relativeUrl = `api/v1/energyTransfer/`;

  const url = `${baseUrl}${relativeUrl}`;

  return fetch(url, config)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res);
      }
      return res;
    })
    .catch((e) => handleErrors(e));
};

//BE API call to update the user details
const updateProducingStation = async (config) => {
  const relativeUrl = `api/v1/producingStations/`;

  const url = `${baseUrl}${relativeUrl}`;

  return fetch(url, config)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res);
      }
      return res.json();
    })
    .catch((e) => handleErrors(e));
};

//BE API call to get all the producers of current user
const getProducingStations = async (config) => {
  const relativeUrl = `api/v1/producingStations/`;

  const url = `${baseUrl}${relativeUrl}`;

  return fetch(url, config)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res);
      }
      return res;
    })
    .catch((e) => handleErrors(e));
};

//BE API call to get all the consumers of the current user
const getConsumingStations = async (config) => {
  const relativeUrl = `api/v1/consumingstations/`;

  const url = `${baseUrl}${relativeUrl}`;

  return fetch(url, config)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res);
      }
      return res;
    })
    .catch((e) => handleErrors(e));
};

//BE API call to cancel the current service
const cancelService = async (config, id) => {
  const relativeUrl = `api/v1/services/${id}/cancel`;

  const url = `${baseUrl}${relativeUrl}`;
  return fetch(url, config).then((res) => {
    return res.json();
  });
};

//BE API call to remove the current producer
const removeProducingStation = async (config, id) => {
  const relativeUrl = `api/v1/producingStations/${id}/remove`;

  const url = `${baseUrl}${relativeUrl}`;
  return fetch(url, config).then((res) => {
    if (!res.ok) {
      throw new Error(res);
    }
    return res.json();
  });
};

//BE API call to remove the current consumer
const removeConsumingStation = async (config, id) => {
  const relativeUrl = `api/v1/consumingstations/${id}/remove`;

  const url = `${baseUrl}${relativeUrl}`;
  return fetch(url, config)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res);
      }
      return res.json();
    })
    .catch((e) => handleErrors(e));
};

//BE API call to replenish the balance of the current service
const replenishService = async (config, id) => {
  const relativeUrl = `api/v1/services/${id}/replenish`;

  const url = `${baseUrl}${relativeUrl}`;
  return fetch(url, config).then((res) => {
    return res.json();
  });
};

//function to handle errors
function handleErrors(e) {
  console.log("Error", e);
}

//function to handle response of the be calls
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
      }

      const error = (data && data.message) || response.statusText;

      return Promise.reject(error);
    }

    return data;
  });
}

// Fetches all user reviews of a producer
function getProducingStationReviews(pid, publicAddress) {
  const relativeUrl = `api/v1/reviews/producingStations/${pid}?limit=10000&offset=0`;
  const url = `${baseUrl}${relativeUrl}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      publicAddress: publicAddress,
    },
  };

  return fetch(url, config);
}

// Fetches all user reviews of a producer
function postProducingStationReviews(
  publicAddress,
  ProducingStationId,
  ConsumingStationId,
  rating,
  comment
) {
  const relativeUrl = `api/v1/reviews`;
  const url = `${baseUrl}${relativeUrl}`;

  const payload = JSON.stringify({
    ProducingStationId,
    ConsumingStationId,
    rating,
    comment,
  });

  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      publicAddress: publicAddress,
    },
    body: payload,
  };

  return fetch(url, config);
}

export default {
  addUser,
  addProducingStation,
  addConsumingStation,
  getRecommended,
  getConsumingStations,
  signIn,
  addService,
  userProducingService,
  userConsumingService,
  userServices,
  removeProducingStation,
  cancelService,
  removeConsumingStation,
  replenishService,
  getProducingStations,
  recordEnergyTransfer,
  updateProducingStation,
  getProducingStationReviews,
  postProducingStationReviews,
};
