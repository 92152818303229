import React from "react";
import Video from "./video";
import { Row } from "reactstrap";
import { Box, Stack, Typography } from "@mui/material";

import "../../css/video.css";
//p2pconnect layout component
const p2pconnect = () => {
  const styles = {
    image: {
      height: "300px",
      maxWidth: "300px",
    },
  };
  return (
    <Stack spacing={2} alignItems={"center"} display={{ md: "flex" }} p={4}>
      <Typography variant="h4">P2P CONNECT PLATFORM:</Typography>
      <img
        src="/images/shutterstock_536590567w.jpg"
        style={styles.image}
        height="100px"
        width="320px"
        alt="skyline"
      />
      <Typography textAlign={"center"}>
        Updating the grid to meet the challenges of the 21st century means going
        peer-to-peer. It’s high time consumers had a say in how their
        electricity is produced. And for producers, peer-to-peer power trading
        means enhanced returns on both residential- and commercial-scale
        renewable energy projects.
      </Typography>
      <img
        src="/images/p2pconnect-cem.png"
        style={styles.image}
        alt="p2p components"
      />
      <Typography textAlign={"center"}>
        By decentralizing the flow of power, P2PConnect combines the potency of
        open markets with the mutual benefits of collective action. That's
        because at Power2Peer, we understand that making renewable energy more
        financially viable is the best chance we have at reducing carbon
        emissions and conserving the health of our planet.
      </Typography>
      <Box display={{ xs: "none", sm: "block" }}>
        <Video embedId="AFdV530ZkWA" style={styles.video} />
      </Box>
    </Stack>
  );
};

export default p2pconnect;
