import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

function Toast({ message, severity, open, handleClose }) {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert
        variant="filled"
        onClose={handleClose}
        severity={severity}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default Toast;
