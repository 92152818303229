import web3 from '../config/web3'
import P2P from '../config/P2PAbi'
import { ethers } from 'ethers'

//SC method call to get all the accounts of metamask
const getAccounts = async () => {
  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  return accounts;
};

//SC method call to get the current metamask account
const getAccount = async () => {
  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  return accounts[0];
};

//SC method call to register a new user
const register = async (account, deposit) => {
  console.log(deposit);
  const minimumDeposit = await P2P.methods.minimumDeposit().call();
  console.log('Minimum Deposit:', minimumDeposit);
  // Convert deposit to wei
  const depositInWei = ethers.utils.parseUnits(deposit.toString(), 'ether');
  console.log('Deposit (in Wei):', depositInWei.toString());
  const status = await P2P.methods.register().send({
    from: account,
    gas: 2000000,
    value: ethers.utils.parseEther(Number(deposit).toFixed(18)),
  });
  if (!status.ok) {
    window.location.reload();
  }
};

//SC method call to get the balance of the current user
const getBalance = async (account) => {
  let balance = 0;

  try {
    const res = await P2P.methods.users(account).call();
    balance = res.balance.toString();

    return web3.utils.fromWei(balance, 'ether')
  } catch (error) { }
}

//SC method call to add funds to the current user
const addFunds = async (account, ethbal) => {
  const res = await P2P.methods.addFunds().send({
    from: account,
    gas: 2000000,
    value: ethers.utils.parseEther(String(ethbal.toFixed(6))),
  });
  return res;
};


//SC method call to withdraw funds from the current user
const withdrawFunds = async (account, ethbal) => {
  const ethVal = ethers.utils.parseEther(String(ethbal.toFixed(6)))
  const res = await P2P.methods.withdrawFunds(ethVal).send({
    from: account,
    gas: 2000000,
  });
  return res;
};

//SC method call to add new producer for the current user
const addProducingStation = async (account, capacity) => {
  const res = await P2P.methods
    .addProducingStation(parseInt(capacity))
    .send({ from: account, gas: 1000000 });
  return res;
};

//SC method call to add new consumer to the current user
const addConsumingStation = async (account) => {
  let res;
  try {
    res = await P2P.methods.addConsumingStation().send({ from: account, gas: 1000000 })
  } catch (error) { }
  return res
}

//sc method call to create a service between a prodcuer and a consumer
const requestService = async (selectedId, id, capacity, limit, account) => {
  limit = Number.parseFloat(limit).toFixed(18);
  const res = await P2P.methods
    .requestService(
      parseInt(selectedId),
      parseInt(id),
      parseInt(capacity),
      web3.utils.toWei(String(limit))
    )
    .send({
      from: account,
      gas: 2000000,
    });

  return res;
};

//SC method call to cancel the selected service
const cancelService = async (account, accNumber) => {
  const res = await P2P.methods
    .cancelService(web3.utils.toHex(accNumber))
    .send({ from: account, gas: 1000000 });
  return res;
};

//SC method call to replenish the balance of a service
const replenishService = async (account, accNumber, amt) => {
  var amount = amt.toFixed(8);
  const res = await P2P.methods
    .replenishService(
      parseInt(accNumber),
      ethers.utils.parseEther(String(amount))
    )
    .send({ from: account, gas: 1000000 });
  return res;
};

//SC method call to transfer energy between producer and consumer by transacting the amount
const recordEnergyTransfer = async (account, accNumber, amt) => {
  const res = await P2P.methods
    .recordEnergyTransfer(
      parseInt(accNumber),
      ethers.utils.parseEther(String(amt))
    )
    .send({ from: account, gas: 1000000 });
  return res;
};

//SC method call to remove the selected producer for user
const removeProducingStation = async (id, account) => {
  const res = await P2P.methods
    .removeProducingStation(parseInt(id))
    .send({ from: account, gas: 1000000 });
  return res;
};

//SC method call to remove the selected consuemr for the user
const removeConsumingStation = async (id, account) => {
  const res = await P2P.methods
    .removeConsumingStation(parseInt(id))
    .send({ from: account, gas: 1000000 });
  return res;
};

//SC method call to detect the chain the metamask wallet is on
const detectChain = async () => {
  const chainId = process.env.REACT_APP_HEX_CHAIN_ID;
  const curChainId = await window.ethereum.request({ method: "eth_chainId" });
  if (curChainId == chainId) {
    return true;
  } else {
    return false;
  }
};

//SC method to switch chain to the approprate chain
const switchChain = async () => {
  const chainId = process.env.REACT_APP_HEX_CHAIN_ID;
  return await window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId }],
  });
};
//SC method to all the appropriate chain in metamask
const addChain = async () => {
  const chainId = process.env.REACT_APP_HEX_CHAIN_ID;
  const chainName = process.env.REACT_APP_CHAIN_NAME;
  const rpcUrls = process.env.REACT_APP_RPC_URLS;
  const nameSym = process.env.REACT_APP_NAME_SYM;
  const blockExplorerUrls = process.env.REACT_APP_BLOCKEXPLOREURLS;

  return await window.ethereum.request({
    jsonrpc: "2.0",
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: chainId,
        chainName: chainName,
        rpcUrls: [rpcUrls],
        nativeCurrency: {
          name: nameSym,
          symbol: nameSym,
          decimals: 18,
        },
        blockExplorerUrls: [blockExplorerUrls],
      },
    ],
  });
};
const unregisterUser = async () => {
  try {
    const account = await getAccount();
    console.log("acc", account)
    await P2P.methods.unregisterUser().send({ from: account });
    console.log("User unregistered successfully.");
  } catch (error) {
    console.error("Error unregistering user:", error);
    throw error;
  }
}

export default {
  unregisterUser,
  getAccounts,
  getBalance,
  register,
  addFunds,
  addProducingStation,
  addConsumingStation,
  getAccount,
  withdrawFunds,
  requestService,
  cancelService,
  replenishService,
  removeProducingStation,
  removeConsumingStation,
  recordEnergyTransfer,
  detectChain,
  switchChain,
  addChain,
};
