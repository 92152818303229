import { createContext, useState, useRef } from "react";
export const User = createContext();

//context layer
const Context = ({ children }) => {
  const emptyuser = JSON.parse(localStorage.getItem("user")) || {
    id: "",
    firstname: "",
    lastname: "",
    email: "",
  };

  const p2pconnect = useRef(null);
  const [isLogged, setIsLogged] = useState(
    localStorage.getItem("isLogged") || false
  );

  const [user, setUser] = useState(emptyuser);
  const [producers, setProducers] = useState([]);
  const [consumers, setConsumers] = useState([]);
  const [consumingservice, setConsumingservice] = useState([]);
  const [producingservice, setProducingservice] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [toeth, setToeth] = useState(0);
  const [tousd, setTousd] = useState(0);
  const [balance, setBalance] = useState(0);

  return (
    <User.Provider
      value={{
        user,
        setUser,
        isLogged,
        setIsLogged,
        p2pconnect,
        producers,
        setProducers,
        consumers,
        setConsumers,
        consumingservice,
        setConsumingservice,
        producingservice,
        setProducingservice,
        tousd,
        setTousd,
        modalShow,
        setModalShow,
        toeth,
        setToeth,
        balance,
        setBalance,
      }}
    >
      {" "}
      {children}
    </User.Provider>
  );
};

export default Context;
