import { Typography, Box } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#666666",
  textAlign: "justify",
  padding: 16,
}));

//our technology layout component
const Ourtechnology = () => {
  const styles = {
    header: {
      backgroundImage: `url('/images/shutterstock_536590567w.jpg')`,
      width: "100%",
      height: "1385px",
      margin: "0",
      position: "absolute",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      opacity: "0.1",
      textAlign: "left",
      zIndex: "1",
    },
    header2: {
      backgroundImage: `url('/images/shutterstock_1297209118w.jpg')`,
      width: "100%",
      height: "2203px",
      margin: "0",
      position: "absolute",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      opacity: "0.1",
      textAlign: "left",
      zIndex: "1",
    },
    header3: {
      backgroundImage: `url('/images/shutterstock_1315034816w.jpg')`,
      width: "100%",
      height: "1733px",
      margin: "0",
      position: "absolute",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      opacity: "0.1",
      textAlign: "left",
      zIndex: "1",
    },
    title: {
      textAlign: "center",
      opacity: "1 !important",
      marginTop: "4rem",
    },
    image: {
      verticalAlign: "middle",
      height: "212.96px",
      width: "320.67px",
      justifyContent: "center",
      marginTop: "2rem",
      opacity: "1 !important",
    },

    content: {
      height: "100%",
      width: "100%",
    },

    text: {
      margin: "10px",
      textAlign: "left",
      padding: "1rem 8rem",
      color: "#666666",
    },
    sectionContent: {
      position: "relative",
      zIndex: "20",
      top: "3rem",
    },

    hr: {
      backgroundImage: `url('/images/hr1.png')`,
      position: "relative",
      marginLeft: "45%",
      height: "7px",
      width: "164px",
    },
    main: {
      maxWidth: "1263.33px",
      padding: "0",
    },
    section2: {
      position: "relative",
      clear: "both",
    },
    video: {
      paddingBottom: "3rem",
      align: "center",
    },
    heading: {
      paddingLeft: "2rem",
      paddingTop: "1rem",
    },
  };

  return (
    <Box textAlign={"center"}>
      {/* <div style={styles.header2}> </div> */}
      <Box
        sx={{ background: "#fbfaf9" }}
        p={{ xs: "16px 32px", sm: "16px 40px", md: "16px 80px" }}
      >
        <h2 className="sec-title ex" style={styles.title}>
          P2PCONNECT CLEAN ENERGY MARKETPLACE VISION
        </h2>
        <div className="hr" style={styles.hr}></div>
        <div className="content">
          <img
            src="/images/shutterstock_1297209118w.jpg"
            style={styles.image}
            height="321px"
            width="213px"
            alt="how does it work?"
          />
          <h3 style={styles.heading}>How does it work?</h3>
          <StyledTypography>
            Power2Peer's vision with P2PConnect is to create a scalable
            blockchain-secured energy trading platform that will enable the
            purchase and sale of millions of clean energy kilowatts between
            peers. A minimum viable product (MVP) of the P2PConnect platform is
            near completion which will demonstrate the viability of the
            commercial product.
          </StyledTypography>
          <StyledTypography>
            The P2PConnect platform consists of a mobile app where users
            register as producer, consumer, or both. All energy transactions are
            logged in the blockchain ledger. The Software-Defined Network
            Controller (SDNC) responds to a transaction by sending information
            to the microgrid, creating optimal connections to facilitate the
            transmission of power from clean energy sources to end-users.
          </StyledTypography>
          <StyledTypography>
            P2PConnect is a free downloadable app that is designed to bring
            consumers clean energy at the best price by reducing the
            transmission and management costs of the kilowatts delivered.
            Customers will have access through the app to a ledger of all
            transactions on the blockchain as well as their billing and usage
            statistics.
          </StyledTypography>
          <h3 style={styles.heading}>
            Create the EV-friendly Virtual Clean Power Network (VCPN)
          </h3>
          <StyledTypography>
            It's time to bring the grid into the 21st century. You can learn
            more about the Power2Peer vision today by reading our white paper!
          </StyledTypography>
          <StyledTypography>
            By decentralizing the flow of power, P2PConnect combines the potency
            of open markets with the mutual benefits of collective action.
            That's because at Power2Peer, we understand that making renewable
            energy more financially viable is the best chance we have at
            reducing carbon emissions and conserving the health of our planet.
          </StyledTypography>
          <h3 style={styles.heading}>
            Core Platform for the Software Defined Network Controller (SDNC)
          </h3>
          <StyledTypography>
            A team of engineers under the guidance and supervision of Dr. Nish
            Sonwalkar (ScD, MIT), founder and chief scientist of Power2Peer, has
            successfully demonstrated the efficacy of Power2Peer's Software
            Defined Network (SDN) controller platform.
          </StyledTypography>
          <h3 style={styles.heading}>
            Create the EV-friendly Virtual Clean Power Network (VCPN)
          </h3>
          <StyledTypography>
            Our solution is to create Virtual Clean Power Network (VCPN) of the
            solar DERs and enable distribution of clean energy to EV charging
            stations. The energy is then delivered to storage battery in the
            EVs. The pool of EV connected to the VCPN then can be used as
            resource to power the traditional grid to manage the peak demand.
            Once the EV batteries are charged with clean energy the impact is
            significant reduction in carbon emission. The VCPN connects the
            power producers with the EV charging companies with the secure
            transacting network created by the block-chain architecture.
          </StyledTypography>
          <img
            src="/images/shutterstock_336054335w.jpg"
            style={styles.image}
            height="321px"
            width="213px"
            alt="our technology"
          />
          <StyledTypography>
            This technology allows Power2Peer to control solar power generation
            systems remotely via cloud computing systems. Currently, control
            systems use software embedded in the hardware on site to control the
            flow of electricity. But with this latest innovation from the
            Power2Peer engineering team, power systems will be controlled with
            cloud-based architecture complete with battery back-up.
          </StyledTypography>
          <StyledTypography>
            This is all accomplished through the cloud. The Software-Defined
            Network Controller makes intelligent decisions about which relays
            need to be activated to ensure electricity is available to each
            household. This kind of resilient solar power system is thus called
            a self-healing power system.
          </StyledTypography>
        </div>
      </Box>
    </Box>
  );
};

export default Ourtechnology;
