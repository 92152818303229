import { useState } from "react";
const Web3 = require("web3");
// Initialize web3 provider (replace with your provider, e.g., Infura, Alchemy)
const web3 = new Web3(
    "https://sepolia.infura.io/v3/8a832c4f8e284eca8fe539535a83093f"
);
const TransactionDebug = () => {
    const [txHash, setTxHash] = useState("");
    async function getRevertReason() {
        try {
            // Get the transaction receipt
            const receipt = await web3.eth.getTransactionReceipt(txHash);
            if (!receipt) {
                console.error("Transaction receipt not found");
                return;
            }

            // Check if the transaction was successful or reverted
            if (!receipt.status) {
                // Transaction reverted
                const tx = await web3.eth.getTransaction(txHash);
                const result = await web3.eth.call(tx, tx.blockNumber);

                // Remove '0x' prefix and extract the revert reason
                const reason = result.substr(138);
                const revertReason = web3.utils.hexToAscii(reason);
                console.error(
                    "Transaction reverted with reason:",
                    revertReason
                );
            } else {
                console.log("Transaction successful!");
            }
        } catch (error) {
            console.error("Error fetching transaction receipt:", error);
        }
    }
    return (
        <>
            <input type="text" onChange={(e) => setTxHash(e.target.value)} />
            <button onClick={getRevertReason}>DEBUG </button>
        </>
    );
};

export default TransactionDebug;
