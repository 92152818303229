import { useState, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Rating from "@mui/material/Rating";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import {
  Button,
  Box,
  Stack,
  Popover,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TableContainer,
} from "@mui/material";

import MultiSelect from "../../components/MultiSelect";
import P2PBackendService from "../../services/p2papiservice";
import { states, types } from "../../components/selectOptions";
import { boxStyles } from "../../components/commonStyles";
import PageTitle from "../../components/PageTitle";
import BuyPower from "./BuyPower";
import Reviews from "./Reviews";

const headCells = ["Name", "Capacity (kWh)", "Avg. price", "Avg. Rating"];

function RecommendedProducers({ selectedCS, setToast }) {
  const publicAddress = localStorage.getItem("publicAddress");

  const [recommendations, setRecommendations] = useState(null);
  const [page, setPage] = useState(0);
  const [selectedPS, setSelectedPS] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [filterTypes, setFilterTypes] = useState([]);
  const [filterStates, setFilterStates] = useState([]);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const [sortOrder, setSortOrder] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [producingStation, setProducingStation] = useState(null);
  const [reviewDialogOpen, setReviewDialogOpen] = useState(false);
  //   When user clicks buy against a particular table row
  const handleBuyClick = (p) => {
    setSelectedPS(p);
    setShowConfirmation(true);
  };

  useEffect(() => {
    // Get a list of recommended producers for the user
    async function getRecommendedProducers() {
      let params = "v2?";
      if (lat) params += "lat=" + lat + "&long=" + long;
      if (states) params += "&state=" + states;
      if (types) params += "&type=" + types;
      if (sortBy) params += "&" + sortBy;
      let options = { publicAddress: publicAddress };
      let res = await P2PBackendService.getRecommended(options, params);
      if (!res.ok) {
        // TODO: Handle this error
      } else {
        res = await res.json();
        setRecommendations(res.data);
      }
    }
    // Get the sorting and filtering params ready
    let lat = selectedCS?.Address?.location?.coordinates[1];
    let long = selectedCS?.Address?.location?.coordinates[0];
    let states = filterStates.map((s) => s.abbreviation).join(",");
    let types = filterTypes.map((t) => t.name).join(",");
    let sortBy =
      sortOrder && sortOrder?.split("_")[0] + "=" + sortOrder?.split("_")[1];

    getRecommendedProducers();
  }, [sortOrder, publicAddress, filterTypes, filterStates, selectedCS]);

  const handleViewReviews = (p) => {
    setProducingStation(p);
    setReviewDialogOpen(true);
  };

  return (
    <>
      <Box sx={{ ...boxStyles }}>
        <Box display="flex" justifyContent={"space-between"}>
          <PageTitle
            title={"Pick a producing station"}
            subtext={"Choose the producing station you want to buy power from"}
            type="sub"
          />
          <Box>
            <Button
              size="small"
              variant="outlined"
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
              sx={{ marginRight: "20px" }}
            >
              Sort by
            </Button>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => {
                setAnchorEl(null);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
            >
              <FormControl sx={{ padding: 2 }}>
                <RadioGroup
                  value={sortOrder}
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  onChange={(e) => {
                    setSortOrder(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="distanceOrder_DESC"
                    control={<Radio />}
                    label="Distance"
                  />

                  <FormControlLabel
                    value="avgRatingOrder_DESC"
                    control={<Radio />}
                    label="Avg. Rating"
                  />
                  <FormControlLabel
                    value="avgPriceOrder_ASC"
                    control={<Radio />}
                    label="Avg. Price &uArr;"
                  />
                  <FormControlLabel
                    value="avgPriceOrder_DESC"
                    control={<Radio />}
                    label="Avg. Price &dArr;	"
                  />
                </RadioGroup>
              </FormControl>
            </Popover>
            <Button
              size="small"
              variant="outlined"
              onClick={(e) => {
                setFilterAnchorEl(e.currentTarget);
              }}
            >
              Filters
            </Button>
            <Popover
              open={Boolean(filterAnchorEl)}
              anchorEl={filterAnchorEl}
              onClose={() => {
                setFilterAnchorEl(null);
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Box
                padding={"32px 16px"}
                sx={{ width: "400px", maxWidth: "100% " }}
              >
                <Stack spacing={2}>
                  <MultiSelect
                    value={filterStates}
                    onChange={setFilterStates}
                    options={states}
                    label="Filter by State"
                  />
                  <MultiSelect
                    value={filterTypes}
                    onChange={setFilterTypes}
                    options={types}
                    label="Filter by type"
                  />
                </Stack>
              </Box>
            </Popover>
          </Box>
        </Box>
        {recommendations && (
          <TableContainer
            sx={{
              overflowX: "auto",
              width: { xs: "calc(100vw - 160px)", md: "100%" },
            }}
          >
            <Table size="small" sx={{ marginTop: 2 }}>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell} align="left" padding="normal">
                      {headCell}
                    </TableCell>
                  ))}
                  <TableCell>{""}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {recommendations.slice(page * 10, page * 10 + 10).map((r) => (
                  <TableRow key={r.id}>
                    <TableCell>{r.name}</TableCell>
                    <TableCell>{r.capacity}</TableCell>
                    <TableCell>{r.avgPrice}</TableCell>
                    <TableCell>
                      <Rating name="read-only" value={r.avgRating} readOnly />
                      <Button size="small" onClick={() => handleViewReviews(r)}>
                        View reviews
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={
                          !Boolean(selectedCS)
                            ? "Please select a consuming station first"
                            : ""
                        }
                        placement="left"
                      >
                        <div>
                          <Button
                            size="small"
                            variant="contained"
                            disabled={!Boolean(selectedCS)}
                            onClick={() => handleBuyClick(r)}
                          >
                            Buy
                          </Button>
                        </div>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <TablePagination
              sx={{ "& .MuiTablePagination-displayedRows": { margin: 0 } }}
              component="div"
              rowsPerPageOptions={[]}
              count={recommendations.length}
              rowsPerPage={10}
              page={page}
              onPageChange={(e, newPage) => setPage(newPage)}
            />
          </TableContainer>
        )}
      </Box>

      {/* Modal for buying energy */}
      <BuyPower
        open={showConfirmation}
        handleClose={() => setShowConfirmation(false)}
        setToast={setToast}
        selectedCS={selectedCS}
        selectedPS={selectedPS}
      />
      {/* Reviews Dialog */}
      <Reviews
        open={reviewDialogOpen}
        handleClose={() => setReviewDialogOpen(false)}
        producingStation={producingStation}
      />
    </>
  );
}

export default RecommendedProducers;
