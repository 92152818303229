import { useState, useContext } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import P2PBackendService from "../../services/p2papiservice";
import { User } from "../../context/context";
import web3service from "../../services/web3service";
import { LoadingButton } from "@mui/lab";
import { emptyAddress } from "../../components/AddressTemplate";
import { states } from "../../components/selectOptions";
import { getCoordinates } from "../../components/getCoordinates";

const textFieldStyles = {
  "&.MuiTextField-root": { marginTop: "28px" },
  width: { xs: "100%", md: "45%" },
};

function AddConsumingStation({
  open,
  handleClose,
  mode = "add",
  setToast,
  setAddedNewStation,
}) {
  const publicAddress = localStorage.getItem("publicAddress");

  const { user } = useContext(User);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [avgConsumption, setAvgConsumption] = useState("");
  const [meterNumber, setMeterNumber] = useState("");

  const [address, setAddress] = useState(emptyAddress);

  //call sc and be methods to add a new consuming station for the current user
  const addConsumingStation = async () => {
    setLoading(true);
    // Get coordinates
    let addressWithCoordinates = await getCoordinates(address);

    let payload = {
      name: name,
      UserId: user.id,
      onChainIndex: 0,
      meterAddress: meterNumber,
      Address: addressWithCoordinates,
      avgConsumption: avgConsumption,
    };

    const registerStatus = await web3service.addConsumingStation(publicAddress);
    if (registerStatus === undefined) {
      // Show an error here
      setLoading(false);
      setToast({
        message:
          "There was an error adding the consuming station, please try again later.",
        severity: "error",
      });
    } else {
      const entityId = registerStatus.events.EntityEvent.returnValues.entityId;
      payload.onChainIndex = entityId;
      // Make API call to the backend
      payload.Address = address;
      payload = JSON.stringify(payload);
      let config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          publicAddress: publicAddress,
        },
        keepalive: true,
        body: payload,
        redirect: "follow",
      };
      const res = await P2PBackendService.addConsumingStation(config);
      if (!res?.ok) {
        // Show error toast
        setToast({
          message:
            "There was an error adding the consuming station, please try again later.",
          severity: "error",
        });
      } else {
        setToast({
          message: "Successfully added consuming station!",
          severity: "success",
        });
        // Clear input fields
        setAddress(emptyAddress);
        setName("");
        setAvgConsumption(0);
      }
    }
    setLoading(false);
  };

  const saveStation = async (e) => {
    e.preventDefault();
    await addConsumingStation();
    // onSubmit();
    setAddedNewStation((p) => p + 1);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={(e, r) => {
        r !== "backdropClick" && handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="md"
    >
      <Box padding={2}>
        <Typography variant="h5" fontWeight={600} p={"20px 24px"}>
          {mode === "add" ? "Add Consuming Station" : "Edit Consuming Station"}
        </Typography>
        <form onSubmit={(e) => saveStation(e)}>
          <DialogContent sx={{ paddingTop: 0 }}>
            <Box display="flex" flexWrap="wrap" justifyContent="space-between">
              <TextField
                size="small"
                id="cs-name-field"
                label="Station name"
                variant="outlined"
                sx={{
                  ...textFieldStyles,
                  width: { xs: "100%", md: "45%" },
                }}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              />
              <TextField
                size="small"
                id="cs-meter-field"
                label="Avg. Consumption (kWh)"
                variant="outlined"
                type="number"
                sx={{
                  ...textFieldStyles,
                  width: { xs: "100%", md: "45%" },
                }}
                value={avgConsumption}
                onChange={(e) => {
                  setAvgConsumption(e.target.value);
                }}
                required
              />
              <TextField
                size="small"
                id="cs-address1-field"
                label="Street address"
                variant="outlined"
                sx={{
                  ...textFieldStyles,
                  width: { xs: "100%", md: "45%" },
                }}
                value={address.line1}
                onChange={(e) => {
                  setAddress((p) => ({ ...p, line1: e.target.value }));
                }}
                required
              />
              <TextField
                size="small"
                id="cs-address2-field"
                label="Street address 2"
                variant="outlined"
                sx={{
                  ...textFieldStyles,
                  width: { xs: "100%", md: "45%" },
                }}
                value={address.line2}
                onChange={(e) => {
                  setAddress((p) => ({ ...p, line2: e.target.value }));
                }}
              />
              <TextField
                size="small"
                id="cs-city-field"
                label="City"
                variant="outlined"
                sx={{
                  ...textFieldStyles,
                  width: { xs: "100%", md: "30%" },
                }}
                value={address.city}
                onChange={(e) => {
                  setAddress((p) => ({ ...p, city: e.target.value }));
                }}
                required
              />
              <FormControl
                size="small"
                sx={{
                  "&.MuiFormControl-root": { marginTop: "28px" },

                  width: { xs: "100%", md: "30%" },
                }}
              >
                <InputLabel> State</InputLabel>
                <Select
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  value={address.state.abbreviation}
                  label="State"
                  onChange={(e) => {
                    setAddress((p) => ({ ...p, state: e.target.value }));
                  }}
                >
                  {states.map((state) => (
                    <MenuItem
                      key={state.abbreviation}
                      value={state.abbreviation}
                    >
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                type="number"
                size="small"
                id="cs-zip-field"
                label="ZIP Code"
                variant="outlined"
                sx={{
                  ...textFieldStyles,
                  width: { xs: "100%", md: "30%" },
                }}
                value={address.zip}
                onChange={(e) => {
                  setAddress((p) => ({ ...p, zip: e.target.value }));
                }}
                required
              />
              <TextField
                size="small"
                id="cs-mno-field"
                label="Meter no."
                variant="outlined"
                sx={{
                  ...textFieldStyles,
                  width: { xs: "100%", md: "30%" },
                }}
                value={meterNumber}
                onChange={(e) => {
                  setMeterNumber(e.target.value);
                }}
                required
                helperText="You will find this in your energy bills."
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ marginTop: "30px" }}>
            <Button
              onClick={handleClose}
              sx={{ color: "#718096" }}
              disabled={loading}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              type="submit"
              autoFocus
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
}

export default AddConsumingStation;
