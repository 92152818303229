import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      // The props to change the default for.
      minWidth: "1000px !important", // No more ripple, on the whole application 💣!
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: { 500: "#2B6CB0" },
    gray: { 500: "#333333" },
  },
});

export default theme;
