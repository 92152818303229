import { useState, useEffect } from "react";
import { Button, Box, Stack, Divider } from "@mui/material";

import PageTitle from "../../components/PageTitle";

import StationCard from "../../components/StationCard";
import CardTextGrp from "../../components/CardTextGrp";
import P2PBackendService from "../../services/p2papiservice";
import AddConsumingStation from "./AddConsumingStation";
import Toast from "../../components/Toast";
import ConfirmationModal from "./ConfirmationModal";
import web3service from "../../services/web3service";

const ConsumingStations = () => {
  const publicAddress = localStorage.getItem("publicAddress");

  const title = "My Consuming Stations";
  const subtext =
    "Add new consuming stations, or view the ones previously added.";

  const [consumingStations, setConsumingStations] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [toast, setToast] = useState({ message: "", severity: "success" });
  const [addedNewStation, setAddedNewStation] = useState(0); // Temporary state variable to refetch consumingstations. TODO: smart update list of consumers once new API is ready
  const [loading, setLoading] = useState(false);

  const [deleteActionDetails, setDeleteActionDetails] = useState({
    title: "Confirm delete?",
    text: "",
    station: null,
  });

  // Fetch user's consuming stations
  useEffect(() => {
    async function getUserDetails() {
      let options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          publicAddress: publicAddress,
        },
        keepalive: true,
        redirect: "follow",
      };
      let res = await P2PBackendService.getConsumingStations(options);
      res = await res.json();
      setConsumingStations(res.data);
    }
    getUserDetails();
  }, [addedNewStation, publicAddress]);

  // Request user's confirmation before deletion
  const confirmDelete = (consumingStation) => {
    setConfirmationModalOpen(true);
    setDeleteActionDetails((p) => ({
      ...p,
      text: `Are you sure you want to delete the consuming station "${consumingStation.name}"`,
      station: consumingStation,
    }));
  };

  // Delete a consuming station
  const removeConsumingStation = async () => {
    setLoading(true);
    // Need a try catch here. Transaction failure is not handled gracefully
    try {
      // Call blockchain method
      const requestStatus = await web3service.removeConsumingStation(
        deleteActionDetails.station?.onChainIndex,
        publicAddress
      );

      // If blockchain method succeeded
      if (requestStatus !== undefined) {
        var payload = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            publicAddress: publicAddress,
          },
        };

        let res = await P2PBackendService.removeConsumingStation(
          payload,
          deleteActionDetails.station?.id
        );
        if (!res.errors) {
          // res = await res.json();
          setAddedNewStation((p) => p + 1);
          // Show success
          setToast({
            message: "Successfully deleted the consuming station!",
            severity: "success",
          });
        } else {
          // Show error
          setToast({
            message:
              "There was an error deleting the consuming station, please try again later.",
            severity: "error",
          });
        }
      }
    } catch (e) {
      console.log("ERROR", e);
      // Show error
      setToast({
        message:
          "There was an error deleting the consuming station, please try again later.",
        severity: "error",
      });
    }
    setLoading(false);
    setConfirmationModalOpen(false);
  };

  return (
    <>
      <Stack width={{ xs: "100%", md: "90%" }} maxWidth="700px" spacing={4}>
        {/* Header */}
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <PageTitle title={title} subtext={subtext} />
          <Button
            variant="contained"
            sx={{ minWidth: "80px" }}
            onClick={() => setModalOpen(true)}
          >
            Add +
          </Button>
        </Box>

        {/* Body */}
        {consumingStations && (
          <Stack spacing={2}>
            {consumingStations
              .filter((consumingStation) => consumingStation.active)
              .map((consumingStation) => (
                <StationCard key={consumingStation.id}>
                  <Stack spacing={2}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <CardTextGrp
                        name="Meter no."
                        value={consumingStation.meterAddress || "-"}
                        info="You will find this in your energy bills."
                      />
                      <CardTextGrp name="Name" value={consumingStation.name} />
                      <CardTextGrp
                        name="Avg. consumption"
                        value={consumingStation.avgConsumption + " kWh"}
                      />
                    </Box>
                    <Divider />
                    <Box alignSelf="flex-end">
                      <Button
                        color="error"
                        onClick={() => confirmDelete(consumingStation)}
                      >
                        Delete
                      </Button>
                      {/* <Button>Edit</Button> */}
                    </Box>
                  </Stack>
                </StationCard>
              ))}
          </Stack>
        )}
      </Stack>

      {/* Modal */}
      <AddConsumingStation
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        setToast={setToast}
        setAddedNewStation={setAddedNewStation}
        setLoading={setLoading}
      />
      {/* Toast message */}
      <Toast
        message={toast.message}
        severity={toast.severity}
        open={Boolean(toast.message)}
        handleClose={() => setToast({ message: "", severity: "success" })}
      />
      {/* Confirmation modal */}
      <ConfirmationModal
        open={confirmationModalOpen}
        details={deleteActionDetails}
        confirmAction={() => removeConsumingStation()}
        handleClose={() => setConfirmationModalOpen(false)}
        loading={loading}
      />
    </>
  );
};

export default ConsumingStations;
