import { useState, useContext } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import PageTitle from "../../components/PageTitle";
import TextField from "@mui/material/TextField/TextField";
import web3service from "../../services/web3service";
import { User } from "../../context/context";
import P2PBackendService from "../../services/p2papiservice";

function AddFunds({ s, open, handleClose, setToast }) {
  const publicAddress = localStorage.getItem("publicAddress");

  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const { setBalance, toeth } = useContext(User);

  //get the account balance of the current user
  const getAccountBalance = async () => {
    try {
      // Get user's account balance from SC
      const b = await web3service.getBalance(publicAddress);
      // Set user's account balance in the global state
      setBalance(Number(b));
    } catch (error) {
      console.log("Error", error);
    }
  };

  //   Close the modal after setting appropriate toast messages
  const closeModal = (status) => {
    if (status === "error") {
      setToast({
        message:
          "There was an error adding funds to this service, please try again later.",
        severity: "error",
      });
    } else {
      setAmount(0);
      getAccountBalance();
      setToast({
        message: "Successfully added funds to the service!",
        severity: "success",
      });
    }
    setLoading(false);
    handleClose();
  };

  //add funds to user's P2P balance
  //replenish the service with funds by calling be and sc
  const handleReplenishService = async (e) => {
    e.preventDefault();
    setLoading(true);
    const amountEth = toeth * amount;
    let web3Res;
    try {
      web3Res = await web3service.replenishService(
        publicAddress,
        s.accountNumber,
        amountEth
      );
    } catch (e) {
      // Handle error with a toast
      closeModal("error");
      return;
    }

    if (web3Res === undefined) {
      // Handle error with a toast
      closeModal("error");
      return;
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("publicAddress", publicAddress);
      var data = JSON.stringify({ amount: amountEth });

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          publicAddress: publicAddress,
        },
        keepalive: true,
        body: data,
        redirect: "follow",
      };
      const res = await P2PBackendService.replenishService(
        requestOptions,
        s.id
      );
      console.log(res);
      if (res.code === 200) {
        closeModal("success");
      } else {
        closeModal("error");
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(e, r) => {
        r !== "backdropClick" && handleClose();
      }}
      maxWidth="md"
    >
      <Box padding={2}>
        <Box p="20px 24px">
          <PageTitle
            title="Please enter the amount"
            subtext={
              "Please enter the amount(in USD) that you want to add to the service"
            }
            type="sub"
          />
        </Box>
        <form onSubmit={handleReplenishService}>
          <DialogContent>
            <TextField
              label="Amount (USD)"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              type="number"
              size="small"
              required
            />
          </DialogContent>
          <DialogActions sx={{ marginTop: "30px" }}>
            <Button
              onClick={handleClose}
              sx={{ color: "#718096" }}
              disabled={loading}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              type="submit"
              autoFocus
            >
              Add
            </LoadingButton>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
}
export default AddFunds;
