import Home from "./components/home/home";
import Register from "./components/register/register";
import AppNavbar from "./components/navbar/navbar";
import Market from "./components/market/market";
import Addconsumer from "./components/addconsumer/addconsumer";
import Addproducer from "./components/addproducer/addproducer";
import Simulation from "./components/simulation/simulation";
import ConsumingStations from "./views/ConsumingStations/ConsumingStations";
import ProducingStations from "./views/ProducingStations/ProducingStations";
import Marketplace from "./views/Marketplace/Marketplace";
import MyServices from "./views/MyServices/MyServices";

import { ReactComponent as consumingStationLogo } from "./assets/consumingStations.svg";
import { ReactComponent as producingstationLogo } from "./assets/producingStations.svg";
import { ReactComponent as myServicesLogo } from "./assets/myServices.svg";
import { ReactComponent as marketplaceLogo } from "./assets/marketplace.svg";
import { ReactComponent as simulationLogo } from "./assets/simulation.svg";

export const privateRoutes = [
  {
    name: "Marketplace",
    element: Marketplace,
    path: "/marketplace",
    sideBar: true,
    logo: marketplaceLogo,
  },
  {
    name: "My Consuming Stations",
    element: ConsumingStations,
    path: "/consumingstations",
    sideBar: true,
    logo: consumingStationLogo,
  },
  {
    name: "My Producing Stations",
    element: ProducingStations,
    path: "/producingstations",
    sideBar: true,
    logo: producingstationLogo,
  },
  {
    name: "My Services",
    element: MyServices,
    path: "/myservices",
    sideBar: true,
    logo: myServicesLogo,
  },
  {
    name: "Simulation",
    element: Simulation,
    path: "/simulation",
    sideBar: true,
    logo: simulationLogo,
  },
];

export const publicRoutes = [
  {
    name: "Landing page",
    element: Home,
    path: "/",
    sideBar: false,
  },
  {
    name: "Register",
    element: Register,
    path: "/register",
    sideBar: false,
  },
];

export const donorPages = ["Donate", "All Activity", "All Polls"];
export const charityPages = ["Request Funds", "All Activity", "All Polls"];
