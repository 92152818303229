import React from "react";
import { Button } from "reactstrap";
import "../../css/jumbotron.css";
import { Box } from "@mui/material";

//jumbotron layout component
const Jumbotron = () => {
  const styles = {
    jumbotron: {
      height: "48rem",
    },
    mainBody: {
      position: "relative",
      float: "left",
      marginTop: "4rem",
      marginLeft: "2rem",
    },
    phones: {
      float: "right",
    },
    phone1: {
      position: "relative",
      opacity: "1",
      zIndex: "20",
      top: "10",
      marginLeft: "-3rem",
    },

    phone2: {
      position: "relative",
      opacity: "1",
      zIndex: "20",
      top: "-35rem",
      marginLeft: "-4rem",
    },
    buttonGroup: {
      top: "10",
      marginLeft: "-1rem",
    },
    mainHeader: {
      fontWeight: "200",
      opacity: "1",
      color: "white",
    },
    mainText: {
      fontWeight: "200",
      opacity: "1",
      color: "white",
    },
    button: {
      opacity: "1",
      margin: ".7rem",
      padding: "1rem",
      borderRadius: "2rem",
      backgroundColor: "#312E2E",
      borderColor: "#489F8B",
      color: "#489F8B",
    },
  };
  const ourtech = () => {
    window.scrollTo({
      top: 2100,
      behavior: "smooth",
    });
  };

  return (
    <div className="jumbotron" style={styles.jumbotron}>
      <Box
        className="col-sm-7 col-header-txt lr-padding"
        style={styles.mainBody}
        width={{ xs: "90%", md: "60%" }}
      >
        <h1 className=" animate fadeInLeft" style={styles.mainHeader}>
          Virtual Clean Power Network
        </h1>
        <p className="animate delay-a fadeInLeft" style={styles.mainText}>
          P2PConnect is a mobile app that’s opening up entirely new
          opportunities in the renewable energy sector. Through P2PConnect’s
          secure trading platform, producers of solar, wind, and other forms of
          clean energy will be connected to consumers who wish to purchase from
          them. Join us on our latest venture, creating the Virtual Clean Power
          Network (VCPN) of the solar DERs and enabling the distribution of
          clean energy to EV charging stations.{" "}
        </p>
        <div className="buttonGroup" style={styles.buttonGroup}>
          <Button
            className=" btn-white delay-b animate fadeInUp"
            href="#getappnow"
            style={styles.button}
          >
            Get App Now
          </Button>
          <Button
            className=" btn-transparent delay-c animate fadeInUp"
            href="#OurVision"
            onClick={ourtech}
            style={styles.button}
          >
            Discover More
          </Button>
          <Button
            className=" btn-transparent delay-c animate fadeInUp"
            href="https://www.f6s.com/p2pconnect?follow=1"
            style={styles.button}
          >
            Follow P2P on F6S
          </Button>
        </div>
      </Box>

      <Box
        className="col-sm-5 col-md-offset-2 col-md-3 col-header-img right-padding"
        style={styles.phones}
        display={{ xs: "none", md: "block" }}
        height={"100%"}
      >
        <img
          src="/images/p2pc-phone2-hero.png"
          alt="header-img"
          className="img-header-lg delay-c animate fadeInRightBig"
          style={styles.phone1}
        />
        <img
          src="/images/p2pc-phone1-hero.png"
          alt="header-img"
          className="img-header-sm delay-a animate fadeInRightBig fadeInLeftBigSolid"
          style={styles.phone2}
        />
      </Box>
    </div>
  );
};

export default Jumbotron;
