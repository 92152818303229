import { useState, useContext } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import PageTitle from "../../components/PageTitle";
import TextField from "@mui/material/TextField/TextField";
import web3service from "../../services/web3service";
import P2PBackendService from "../../services/p2papiservice";
import { User } from "../../context/context";

function BuyPower({ open, handleClose, selectedCS, selectedPS, setToast }) {
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState("");
  const { toeth } = useContext(User);

  //add a new service between the selected producing and consuming station
  const buyPower = async (e) => {
    setLoading(true);
    e.preventDefault();

    var myHeaders = new Headers();
    const publicAddress = localStorage.getItem("publicAddress");

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("publicAddress", publicAddress);

    const amount = quantity * selectedPS.avgPrice * toeth;
    let requestStatus;

    try {
      requestStatus = await web3service.requestService(
        selectedCS.onChainIndex, // On chain index of Consumer
        selectedPS.onChainIndex, // On chain index of producer
        Number(quantity), // Capacity added by consumer
        amount, // avg price * quantity
        publicAddress
      );
    } catch (e) {
      setToast({
        message: `There was an error purchasing power. Please try again later.`,
        severity: "error",
      });
      setLoading(false);
      handleClose();
    }

    if (requestStatus !== undefined) {
      const acNo =
        requestStatus.events.ServiceEvent.returnValues.serviceAccountNumber;
      var reqBody = {
        accountNumber: parseInt(acNo),
        capacity: parseInt(quantity),
        amount: amount,
        ProducingStationId: parseInt(selectedPS.id),
        ConsumingStationId: parseInt(selectedCS.id),
      };

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(reqBody),
        redirect: "follow",
      };

      let res = await P2PBackendService.addService(requestOptions);
      if (!res.ok) {
        // Handle error here
        setToast({
          message: `There was an error purchasing power. Please try again later.`,
          severity: "error",
        });
        setLoading(false);
        handleClose();
      } else {
        res = await res.json();
        setToast({
          message: `Successfully purchased power from ${selectedPS.name}!`,
          severity: "success",
        });
        setQuantity("");
        setLoading(false);
        handleClose();
      }
    } else {
      // Handle error here
      setToast({
        message: `There was an error purchasing power. Please try again later.`,
        severity: "error",
      });
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(e, r) => {
        r !== "backdropClick" && handleClose();
      }}
      maxWidth="md"
    >
      <Box padding={2}>
        <Box p="20px 24px">
          <PageTitle
            title="Confirm purchase"
            subtext="Please enter the required quantity of energy and hit buy to confirm purchase"
            type="sub"
          />
        </Box>
        <form onSubmit={buyPower}>
          <DialogContent>
            <TextField
              label="Quantity (kWh)"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              type="number"
              helperText="How much energy(in kWh) do you want to buy?"
              size="small"
              required
              sx={{ marginBottom: 2 }}
            />
            {quantity && (
              <Typography
                variant="subtitle2"
                fontWeight={600}
                color={"#6c757d"}
              >
                Estimated price: {selectedPS.avgPrice * quantity} USD
              </Typography>
            )}
          </DialogContent>
          <DialogActions sx={{ marginTop: "30px" }}>
            <Button
              onClick={handleClose}
              sx={{ color: "#718096" }}
              disabled={loading}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              type="submit"
              autoFocus
            >
              Buy
            </LoadingButton>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
}
export default BuyPower;
