import { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import P2PBackendService from "../../services/p2papiservice";

import { LoadingButton } from "@mui/lab";
import Stack from "@mui/material/Stack";

import PageTitle from "../../components/PageTitle";
import Rating from "@mui/material/Rating";

// Allows user to review a given producer
function ReviewModal({ s, open, handleClose, setToast }) {
  const publicAddress = localStorage.getItem("publicAddress");
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  //   Calls P2P BE with the user's rating and comment
  const submitReview = async (e) => {
    e.preventDefault();
    setLoading(true);
    let res = await P2PBackendService.postProducingStationReviews(
      publicAddress,
      s.ProducingStation.id,
      s.ConsumingStation.id,
      rating,
      comment
    );
    if (res.ok) {
      res = await res.json();
      console.log(res);
      setToast({
        message: "Successfully added your review!",
        severity: "success",
      });
      setRating(0);
      setComment("");
    } else {
      // Error
      setToast({
        message:
          "There was an error adding the review, please try again later.",
        severity: "error",
      });
    }
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={(e, r) => {
        r !== "backdropClick" && handleClose();
      }}
      maxWidth="md"
    >
      {s && (
        <Box p={3}>
          <form onSubmit={submitReview}>
            <Stack spacing={2}>
              <PageTitle
                title={"Add a review for " + s?.ProducingStation?.name}
                subtext="Your review will show up in the Marketplace page for other consumers to view."
                type="sub"
              />

              <Typography variant={"h5"} gutterBottom></Typography>
              <Box>
                <Typography>Add a rating</Typography>
                <Rating
                  required
                  name="simple-controlled"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                />
              </Box>
              <Box>
                <Typography variant="subtitle1">Comments</Typography>
                <TextField
                  required
                  multiline
                  sx={{ width: { xs: "100%" } }}
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </Box>
              <DialogActions sx={{ marginTop: "30px" }}>
                <Button
                  onClick={handleClose}
                  sx={{ color: "#718096" }}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  autoFocus
                >
                  Submit
                </LoadingButton>
              </DialogActions>
            </Stack>
          </form>
        </Box>
      )}
    </Dialog>
  );
}

export default ReviewModal;
