import { useEffect, useState, useContext } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Stack, Divider, Button, Chip } from "@mui/material";
import web3service from "../../services/web3service";

import CardTextGrp from "../../components/CardTextGrp";
import { User } from "../../context/context";
import PageTitle from "../../components/PageTitle";

import P2PBackendService from "../../services/p2papiservice";
import ReplenishModal from "./ReplenishModal";
import Toast from "../../components/Toast";
import ConfirmationModal from "../ConsumingStations/ConfirmationModal";
import ReviewModal from "./reviewModal";

function MyServices() {
  // Feed this to the PageTitle component
  const title = "My Services";
  const subtext =
    "All the services linked to your consuming and producing stations.";
  const publicAddress = localStorage.getItem("publicAddress");

  const { tousd } = useContext(User);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [groupedConsumingServices, setGroupedConsumingServices] =
    useState(null);
  const [groupedProducingServices, setGroupedProducingServices] =
    useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [showReplenishModal, setShowReplenishModal] = useState(false);
  const [toast, setToast] = useState({ message: "", severity: "success" });
  const [loading, setLoading] = useState(false);
  const [cancelledService, setCancelledService] = useState(0); // Temporary state variable to refetch producingstations. TODO: smart update list of consumers once new API is ready
  const [showReviewModal, setShowReviewModal] = useState(false);
  // Fetch all services and group them by the consuming station
  useEffect(() => {
    const getServices = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("publicAddress", publicAddress);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      let res = await P2PBackendService.userServices(requestOptions);
      //  On successful response
      let gcs = {};
      let gps = {};
      if (res?.code === 200) {
        res.data.consumingServices.forEach((s) => {
          // If we'be already added services of this ConsumingStationId to groupedConsumingServices
          if (gcs[s.ConsumingStationId]) {
            gcs[s.ConsumingStationId].push(s);
          } else {
            gcs[s.ConsumingStationId] = [s];
          }
        });
        setGroupedConsumingServices(gcs);

        res.data.producingServices.forEach((s) => {
          // If we'be already added services of this ConsumingStationId to groupedConsumingServices
          if (gps[s.ProducingStationId]) {
            gps[s.ProducingStationId].push(s);
          } else {
            gps[s.ProducingStationId] = [s];
          }
        });
        setGroupedProducingServices(gps);
      }
    };
    getServices();
  }, [publicAddress, cancelledService]);

  // Show modal that takes in amount to be added to the selected service, and calls the SC and BE
  const confirmReplenish = (s) => {
    setSelectedService(s);
    setShowReplenishModal(true);
  };

  const handleClose = (status) => {
    if (status === "success") {
      // Triggers useEffect causing a refetch of services from BE
      setCancelledService((p) => p + 1);
      setToast({
        message: "Successfully deactivated the service!",
        severity: "success",
      });
    } else {
      setToast({
        message:
          "There was an error deactivating this service. Please try again later",
        severity: "error",
      });
    }

    setLoading(false);
    setShowConfirmDelete(false);
  };

  const confirmCancel = (s) => {
    setSelectedService(s);
    setShowConfirmDelete(true);
  };

  const cancelService = async () => {
    setLoading(true);

    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("publicAddress", publicAddress);

    let cancelServiceRes;
    try {
      cancelServiceRes = await web3service.cancelService(
        publicAddress,
        selectedService.accountNumber
      );
    } catch (e) {
      handleClose("error");
    }

    if (cancelServiceRes === undefined) {
      handleClose("error");
    } else {
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        keepalive: true,
        redirect: "follow",
      };

      const res = await P2PBackendService.cancelService(
        requestOptions,
        selectedService.id
      );
      if (res.code === 200) {
        handleClose("success");
      } else {
        handleClose("error");
      }
    }
  };

  const collectReview = (s) => {
    setSelectedService(s);
    setShowReviewModal(true);
  };

  return (
    <>
      <PageTitle title={title} subtext={subtext} />
      <Box sx={{ paddingTop: "40px" }}>
        {groupedConsumingServices &&
          Object.keys(groupedConsumingServices).map((i) => (
            <Accordion key={i}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" fontWeight={600}>
                  Consuming Station:{" "}
                  {groupedConsumingServices[i][0]?.ConsumingStation?.name}
                </Typography>
              </AccordionSummary>
              <AccordionSummary>
                <Stack
                  spacing={3}
                  sx={{ width: "100%" }}
                  divider={
                    <Divider flexItem sx={{ color: "rgba(0, 0, 0, 0.25)" }} />
                  }
                >
                  {groupedConsumingServices[i].map((s) => (
                    <Box key={s.id}>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Chip
                          size="small"
                          label={s.status}
                          color={
                            s.status === "ACTIVE"
                              ? "success"
                              : s.status === "CANCELLED"
                              ? "error"
                              : "warning"
                          }
                        />
                      </Box>
                      <Box
                        key={s.id}
                        display="flex"
                        justifyContent="space-between"
                        flexDirection={{ xs: "column", sm: "row" }}
                        p="0 20px"
                      >
                        <CardTextGrp name="Service ID" value={s.id} />
                        <Box>
                          <CardTextGrp
                            name="Producing Station"
                            value={s.ProducingStation.name || "-"}
                          />
                          {!s.hasReview && (
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={() => collectReview(s)}
                            >
                              Rate Producing Station
                            </Button>
                          )}
                        </Box>
                        <CardTextGrp
                          name="Balance"
                          value={Math.round(s.amount * tousd) + " USD"}
                        />

                        <CardTextGrp
                          name="Capacity"
                          value={s.capacity + " kWh"}
                        />
                      </Box>
                      <Box display="flex" justifyContent="flex-end">
                        {s.status === "ACTIVE" ? (
                          <Button
                            sx={{ marginRight: "8px" }}
                            onClick={() => {
                              confirmReplenish(s);
                            }}
                          >
                            Replenish
                          </Button>
                        ) : (
                          <></>
                        )}

                        {s.status === "ACTIVE" ? (
                          <Button
                            onClick={() => confirmCancel(s)}
                            color="error"
                          >
                            Deactivate
                          </Button>
                        ) : (
                          <></>
                          // <Button color="success">Reactivate</Button>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Stack>
              </AccordionSummary>
            </Accordion>
          ))}

        {/* Add funds modal */}
        <ReplenishModal
          s={selectedService}
          open={showReplenishModal}
          handleClose={() => setShowReplenishModal(false)}
          setToast={setToast}
        />

        {/* Toast message */}
        <Toast
          message={toast.message}
          severity={toast.severity}
          open={Boolean(toast.message)}
          handleClose={() =>
            setToast((p) => ({ message: "", severity: p.severity }))
          }
        />

        <ConfirmationModal
          open={showConfirmDelete}
          handleClose={() => setShowConfirmDelete(false)}
          details={{
            title: `Deactivate service ${selectedService?.id}?`,
            text: "Are you sure you want to deactivate this service?",
          }}
          confirmAction={cancelService}
          loading={loading}
        />

        <ReviewModal
          s={selectedService}
          open={showReviewModal}
          handleClose={() => setShowReviewModal(false)}
          setToast={setToast}
        />
      </Box>

      <Box sx={{ paddingTop: "40px" }}>
        {groupedProducingServices &&
          Object.keys(groupedProducingServices).map((i) => (
            <Accordion key={i}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" fontWeight={600}>
                  Producing Station:{" "}
                  {groupedProducingServices[i][0]?.ProducingStation?.name}
                </Typography>
              </AccordionSummary>
              <AccordionSummary>
                <Stack
                  spacing={3}
                  sx={{ width: "100%" }}
                  divider={
                    <Divider flexItem sx={{ color: "rgba(0, 0, 0, 0.25)" }} />
                  }
                >
                  {groupedProducingServices[i].map((s) => (
                    <Box key={s.id}>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Chip
                          size="small"
                          label={s.status}
                          color={
                            s.status === "ACTIVE"
                              ? "success"
                              : s.status === "CANCELLED"
                              ? "error"
                              : "warning"
                          }
                        />
                      </Box>
                      <Box
                        key={s.id}
                        display="flex"
                        justifyContent="space-between"
                        flexDirection={{ xs: "column", sm: "row" }}
                        p="0 20px"
                      >
                        <CardTextGrp name="Service ID" value={s.id} />
                        <Box>
                          <CardTextGrp
                            name="Consuming Station"
                            value={s.ConsumingStation.name || "-"}
                          />
                        </Box>
                        <CardTextGrp
                          name="Balance"
                          value={Math.round(s.amount * tousd) + " USD"}
                        />

                        <CardTextGrp
                          name="Capacity"
                          value={s.capacity + " kWh"}
                        />
                      </Box>
                      {/* <Box display="flex" justifyContent="flex-end">
                        {s.status === "ACTIVE" ? (
                          <Button
                            onClick={() => confirmCancel(s)}
                            color="error"
                          >
                            Deactivate
                          </Button>
                        ) : (
                          <></>
                          // <Button color="success">Reactivate</Button>
                        )}
                      </Box> */}
                    </Box>
                  ))}
                </Stack>
              </AccordionSummary>
            </Accordion>
          ))}

        {/* Toast message
        <Toast
          message={toast.message}
          severity={toast.severity}
          open={Boolean(toast.message)}
          handleClose={() =>
            setToast((p) => ({ message: "", severity: p.severity }))
          }
        /> */}

        {/* <ConfirmationModal
          open={showConfirmDelete}
          handleClose={() => setShowConfirmDelete(false)}
          details={{
            title: `Deactivate service ${selectedService?.id}?`,
            text: "Are you sure you want to deactivate this service?",
          }}
          confirmAction={cancelService}
          loading={loading}
        /> */}

        {/* <ReviewModal
          s={selectedService}
          open={showReviewModal}
          handleClose={() => setShowReviewModal(false)}
          setToast={setToast}
        /> */}
      </Box>
    </>
  );
}

export default MyServices;
