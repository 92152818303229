import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "@mui/lab";

function ConfirmationModal({
  open,
  handleClose,
  details,
  confirmAction,
  loading,
}) {
  return (
    <Dialog
      open={open}
      onClose={(e, r) => {
        r !== "backdropClick" && handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="md"
    >
      <Box padding={3}>
        <Typography variant="h5" fontWeight={600} gutterBottom>
          {details.title}
        </Typography>
        <Typography>{details.text}</Typography>
        <DialogActions sx={{ marginTop: "30px" }}>
          <Button
            onClick={handleClose}
            sx={{ color: "#718096" }}
            disabled={loading}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={confirmAction}
            color="error"
            loading={loading}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default ConfirmationModal;
