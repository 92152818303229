import React, { useEffect, useState, useContext } from "react";
import { FormGroup, Button, Row, Col } from "reactstrap";
import Card from "react-bootstrap/Card";
import web3service from "../../services/web3service";
import P2PBackendService from "../../services/p2papiservice";
import { FaSolarPanel } from "react-icons/fa";
import { GiHouse } from "react-icons/gi";
import { ImPower } from "react-icons/im";
import "../../css/simulation.css";
import { User } from "../../context/context";
import { toast } from "react-toastify";

//simulation  layout component
const Simulation = () => {
  const [userservices, setUserServices] = useState(null);
  const [selectedservice, setSelectedService] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [results, setResults] = useState(null);
  const [valid, setValid] = useState(false);
  const { tousd } = useContext(User);
  const { toeth } = useContext(User);

  const styles = {
    resultMain: {
      marginLeft: "7rem",
      marginTop: "20rem",
    },
    card: {
      width: "12rem",
    },
    result: {},
    resultCard: {
      width: "25rem",
    },
    btnHandleSimulation: {
      fontWeight: "700",
      position: "relative",
      left: "-7rem",
    },
    consumerCard: {
      width: "12rem",
    },
    colGreen: {
      color: "green",
    },
    colRed: {
      color: "red",
    },
  };
  useEffect(() => {
    //fetch the services of the current user
    const fetchUserServices = async () => {
      var myHeaders = new Headers();
      const publicAddress = localStorage.getItem("publicAddress");

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("publicAddress", publicAddress);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        keepalive: true,
        redirect: "follow",
      };

      const data = await P2PBackendService.userServices(requestOptions);
      setUserServices([...new Set([...data.data.consumingServices, ...data.data.producingServices])]);
    };
    fetchUserServices();
  }, []);

  //track the id of the serlected service
  const handleSelectedServiceIdState = (e) => {
    setIsActive(false);
    let id;
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    if (el != null) {
      id = el.getAttribute("id");
    }
    const filteredservice = userservices.filter(
      (service) => service.accountNumber == id
    );

    setSelectedService(filteredservice[0]);
  };
  //call sc and be recordenergy method
  const handleSimulation = async () => {
    try {
      setIsActive(true);
      var myHeaders = new Headers();
      const accounts = localStorage.getItem("publicAddress");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("publicAddress", accounts);
      const amtInUSD = 2;
      var amtInETH = (2 * toeth).toFixed(6);

      const registerStatus = web3service
        .recordEnergyTransfer(
          accounts,
          selectedservice.accountNumber,
          amtInETH
        )
        .then((result) => {
          const status = result.events.PaymentEvent.returnValues.status;
          if (status == 0) {
            const power = (
              (amtInUSD) /
              (selectedservice.ProducingStation.Prices[0].price)
            ).toFixed(2);
            const res = {
              amount: (amtInUSD).toFixed(2),
              power: power,
            };
            setResults(res);
            setValid(true);
          } else {
            setResults("res");
            toast.error("Less Balance. Please Replenish!");
            setValid(false);
          }
        });
      await toast.promise(registerStatus, {
        pending: "Processing",
        success: "Simulation Completed",
        error: "energy transfer failed",
      });

      if (registerStatus == undefined) {
      } else {
        const data = {
          consumption: selectedservice.capacity,
          accountNumber: selectedservice.accountNumber,
          value: amtInETH
        };

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          keepalive: true,
          body: JSON.stringify(data),
          redirect: "follow",
        };

        const result = await P2PBackendService.recordEnergyTransfer(requestOptions);
      }
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div>
      <h5 className="d-flex align justify-content-center">
        Simulation For Service:
      </h5>
      <Row className="m-4">
        <Col md={4}>
          <FormGroup className="row pt-3" inline>
            <select
              className="col-md-3 form-select "
              onChange={(e) => handleSelectedServiceIdState(e)}
              id="title"
              aria-label="Choose..."
            >
              <option>Choose..</option>
              {userservices != null ? (
                userservices.map((item) =>
                  item.status == "ACTIVE" ? (
                    <option key={item.accountNumber} id={item.accountNumber}>
                      ({item.ProducingStation.name})-({item.ConsumingStation.name}) Service
                    </option>
                  ) : (
                    <></>
                  )
                )
              ) : (
                <></>
              )}
            </select>
          </FormGroup>
        </Col>
      </Row>
      {selectedservice && (
        <Row className="m-4 d-flex row mt-4 justify-content-beetween">
          <Col md={2} className="justify-content-start">
            <Card style={styles.card}>
              <div className="mx-4 px-4">
                <FaSolarPanel size={90} />
              </div>
              <Card.Body>
                <Card.Title className="text-center">ProducingStation</Card.Title>

                <Card.Text>
                  <hr />
                  <Row>Id:&nbsp;{selectedservice.ProducingStation.id}</Row>
                  <Row>Type:&nbsp;{selectedservice.ProducingStation.type}</Row>
                  <Row>Name:&nbsp;{selectedservice.ProducingStation.name}</Row>
                  <Row>Capacity:&nbsp;{selectedservice.ProducingStation.capacity}</Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className=" d-flex align-items-center">
            <div className={isActive == true ? "power" : ""}>
              <hr />
              <ImPower />
              <ImPower />
              <ImPower />
              <hr />
            </div>

            {results ? (
              <div
                className="d-flex  justify-content-center align-items-center"
                style={styles.resultMain}
              >
                <Card style={styles.resultCard}>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Simulation Results
                    </Card.Title>

                    <Card.Text>
                      <hr />
                      {valid ? (
                        <>
                          <Row>
                            <Col md={5}>Amount Spent:&nbsp;</Col>
                            <Col md={7} style={styles.colGreen}>
                              {results.amount} &nbsp;USD
                            </Col>
                          </Row>
                          <Row>
                            <Col md={5}>Power Transferred:&nbsp;</Col>
                            <Col md={7} style={styles.colGreen}>
                              {results.power} &nbsp;kWh
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          <Row>
                            <Col md={12} style={styles.colRed}>
                              Not enough Balance in the Service. Please
                              Replenish!
                            </Col>
                          </Row>
                          <Row></Row>
                        </>
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            ) : (
              ""
            )}
          </Col>
          <Col md={2} className="justify-content-end">
            <Card style={styles.consumerCard}>
              <div className="mx-4 px-4">
                <GiHouse size={90} />
              </div>
              <Card.Body>
                <Card.Title className="text-center">Consuming Station</Card.Title>
                <hr />
                <Card.Text>
                  <Row>Id:&nbsp;{selectedservice.ConsumingStation.id}</Row>
                  <Row>Name:&nbsp;{selectedservice.ConsumingStation.name}</Row>
                  <Row>
                    Avg-Consumption:&nbsp;
                    {selectedservice.ConsumingStation.avgConsumption}{" "}
                  </Row>
                  <Row></Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <div
        className="d-flex justify-content-center m-4 ms-4 mt-4"
        style={styles.btnHandleSimulation}
        onClick={handleSimulation}
      >
        <Button>Start Simulation</Button>
      </div>
    </div>
  );
};

export default Simulation;
