export async function getCoordinates(address) {
  let geoUrl = `https://geocode.maps.co/search?q={${
    address.line1 +
    "," +
    address.line2 +
    "," +
    address.city +
    "," +
    address.state
  }}`;
  geoUrl = encodeURI(geoUrl);
  let res = await fetch(geoUrl);
  let addressWithCoordinates = { ...address };

  // If we recieve correct longitude and latitude from geocode API
  if (res.ok) {
    res = await res.json();
    if (res && res.length > 0) {
      addressWithCoordinates.location.coordinates = [
        Math.round(res[0]?.lon * 100000) / 100000,
        Math.round(res[0]?.lat * 100000) / 100000,
      ];
    }
  }
  return addressWithCoordinates;
}
