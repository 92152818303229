import Typography from "@mui/material/Typography";

function PageTitle({ title, subtext, type = "main" }) {
  return (
    <div>
      <Typography
        fontWeight={600}
        variant={type === "main" ? "h4" : "h5"}
        color={"#2D3748"}
      >
        {title}
      </Typography>
      <Typography variant="subtitle1" color="#718096">
        {subtext}
      </Typography>
    </div>
  );
}

export default PageTitle;
