import React from "react";

const coinApiKey =process.env.REACT_APP_CRYPTOCOMPARE_API_KEY;
const coinApiRoute = `https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD,ETH&api_key=${coinApiKey}`;

//method to fetch the ethe val of the current value
export const FetchEthVal = async (value) => {
  const res = await fetch(coinApiRoute).then((res) => res.json());
  const rates = res;
  const usdConv = rates.USD;
  const ethConv = rates.ETH;
  let toEth = ethConv / usdConv;
  let returnValue = parseFloat(value) * toEth;
  return returnValue;
};

//method to fetch the current toEth and toUsd conversion values
export const FetchEthtoUsd = async () => {
  const res = await fetch(coinApiRoute).then((res) => res.json());
  const rates = res;
  const usdConv = rates.USD;
  const ethConv = rates.ETH;
  let toEth = ethConv / usdConv;
  let toUsd = usdConv / ethConv;
  return { toEth, toUsd };
};
